import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";
import {
  OpenAgentDetail,
  numTomoeny,
  convertDate,
  converBalanceStatus,
  ConverAgentExBalanceType,
  convertFullDate,
  GetToday,
  ConvertCalendar,
  ConverAgentExBalanceTypeColor
} from "../utility/help";

interface AgentState {
  balances: any;
  activePage: any;
  maxCount: any;
  total : any;
  begin : any;
  end : any;
  viewType: any;
  selectViewType: any;
  cities: any;
  dropdownFind: any;
  searchText : any;
}

export class AgentBalanceList extends Component<any, AgentState> {
  AgentService: AgentService  = new AgentService()
  constructor(props: any) {
    super(props);
    
    let today  = GetToday()
    this.state = {
      total : [],
      balances: [],
      activePage: 1,
      maxCount: 1,
      begin : today.begin,
      end : today.end,
      selectViewType: "all",
      dropdownFind: "",
      searchText: "",
      
      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
      ],
      cities: [
        { label: "선택", value: null },
        { label: "이름", value: "bankOwner" },
        { label: "에이전트", value: "agentId" },
      ],
 
    };

    this.handleAgentBalance(1, this.state.selectViewType);

  }

  componentDidMount() {
  }

  handleAgentBalance = (skip : any, selectView :string) => {
    this.setState({ activePage: skip });
    this.AgentService.agent_get_agent_balance_list(convertFullDate(this.state.begin), convertFullDate(this.state.end), skip, selectView, this.state.dropdownFind, this.state.searchText ).then((s) => {
      this.setState({
        total : s.total ,
        maxCount: s.maxCount,
        balances: s.balance,
      });
    });
  };

  handleMoveSel = (page: any) => {
    this.handleAgentBalance(page, this.state.selectViewType);
  };

  render = () => {
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>파트너 충전/환전</h4>
              </div>
              <div className="panel-body">
                <div className="form-inline">
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                      <Calendar
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={new Date(this.state.begin)}
                            showWeek={false}
                            onChange={(e: any) => this.setState({ begin : e.value.toLocaleString('sv-SE') })}
                            showTime
                            locale={ConvertCalendar()}
                          />&nbsp;~&nbsp;
                          <Calendar
                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            showWeek={false}
                            value={new Date(this.state.end)}
                            onChange={(e: any) => this.setState({ end : e.value.toLocaleString('sv-SE') })}
                            showTime
                            locale={ConvertCalendar()}
                          />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                      <Dropdown
                            style={{width : 100}}
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                            className="form-control"
                          />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                        <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            <label>검색</label>
                          </span>
                  </div>
                  {/* Button Start */}
                      <Button
                              label="검색"
                              onClick={() => {
                                 this.handleMoveSel(0);
                              }}
                            />
                  {/* Button End */}
                </div>
              </div>
              <div className="panel-body">
                  <SelectButton
                            value={this.state.selectViewType}
                            options={this.state.viewType}
                            onChange={(event: any) => {
                              this.setState({ selectViewType: event.value });
                              this.handleAgentBalance(0, event.value);
                            }}
                          />
              </div>
              <div className="panel-body">
              <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
              <div className="panel-body">
              
                <DataTable
                  value={this.state.balances}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                >

                <Column 
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <span style={{color : ConverAgentExBalanceTypeColor(agent.type)}}>
                          {ConverAgentExBalanceType(agent.type)}
                        </span>
                      );
                    }}
                    field="agent"
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agentId);
                          }}
                        >
                          {agent.agentId}
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="환전금액/알"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="은행"
                    body={(agent: any) => {
                      return agent.bankname;
                    }}
                    // sortFunction={}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="통장번호"
                    body={(agent: any) => {
                      return agent.banknum;
                    }}
                    // sortFunction={}
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="예금주"
                    body={(agent: any) => {
                      return agent.bankOwner;
                    }}
                    // sortFunction={}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="신청일"
                    body={(agent: any) => {
                      return convertDate(agent.regDate);
                    }}
                  />
                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.setDate);
                    }}
                  />

                  <Column
                     headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
                     style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
                    header="상태"
                    body={(agent: any) => {
                      return converBalanceStatus(agent.status);
                    }}
                  />

                  {/* <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/> */}
                </DataTable>

                
              </div>
              <div className="panel-body">
              <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>





      </div>
    );
  };
}

import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { Panel } from "primereact/panel";
import { confirmAlert } from "react-confirm-alert";
import { AgentService } from "../service/agent.service";
import { CustomPagination } from "../share/custom-pagination";
import { HelpStatus, convertDate } from "../utility/help";

enum Mode {
  none = "none",
  view = "view",
  wirte = "wirte",
}

interface State {
  helps: any[];
  selTemplate: any;
  template: any[];
  help: any;
  activePage: number;
  maxCount: number;
  selectBox: any[];
  dropdownItem: any;
  seach: any;

  mode: string;
}

export class AgentHelp extends Component<any, State> {
  agentService = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
      mode: Mode.none,
      helps: [],
      template: [],
      selTemplate: "",
      activePage: 1,
      maxCount: 1,
      help: {
        mode: "user",
        del: "n",
        contents: "  ",
        title: "",
        userId: "",
        ref: { contents: "<div></div>" },
      },
      seach: "",
      dropdownItem: null,
      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "제목", value: "title" },
        // { label: "내용", value: "text" },
      ],
    };
  }

  componentDidMount() {
    this.handleUserNote(1);
  }

  handleUserNote = async (skip: any) => {
    this.setState({ activePage: skip });
    let data = await this.agentService
      .admin_get_agent_help_list(this.state.help)
      .then((data) => data);

    if (data.status == "success") {
      this.setState({ helps: data.helps, maxCount: data.maxCount });
    }
  };

  handleReadHelp = async (_idx) => {
    await this.agentService.admin_read_agent_help(_idx).then((data) => data);
  };

  handleWirteHelp = async () => {
    let data = await this.agentService
      .admin_wirte_agent_help(this.state.help)
      .then((data) => data);
    if (data.status == "success") {
      confirmAlert({
        title: "고객센터",
        message: "고객센터 작성/수정 성공.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "고객센터",
        message: "고객센터 작성 실패.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    }
  };

  handleDelHelp = async (_idx) => {
    let data = await this.agentService
      .admin_get_agent_help_del(_idx)
      .then((data) => data);

    if (data.status == "success") {
      confirmAlert({
        title: "고객센터",
        message: "고객센터 삭제되었습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "고객센터",
        message: "고객센터 삭제 실패.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    }
  };

  render() {
    let items = this.state.helps;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ help: rowData, mode: Mode.view });
              this.handleReadHelp(rowData._id);
              //   this.handleAlreadRead(rowData)
            }}
          ></Button>
        </div>
      );
    };

    const RenderHelp = () => {
      if (this.state.mode === Mode.none) {
        return <></>;
      }

      if (this.state.mode === Mode.wirte) {
        return (
          <div className="p-col-12 p-md-8">
            <div className="card">
              <div className="p-col-12">
                <span className="p-float-label">
                  <InputText
                    id="in"
                    style={{
                      width: "70%",
                    }}
                    value={this.state.help.title}
                    onChange={(e: any) => {
                      this.state.help.title = e.target.value;
                      this.setState({ help: this.state.help });
                    }}
                  />
                  <label htmlFor="in">제목</label>
                </span>
              </div>

              <div className="p-col-12">
                <Editor
                  style={{ height: "320px" }}
                  value={this.state.help.contents}
                  onTextChange={(e) => {
                    this.state.help.contents = e.htmlValue;

                    this.setState({ help: this.state.help });
                  }}
                  // onChange={(event) =>
                  //   this.setState({ selTemplate: event.value })
                  // }
                />
              </div>

              <div className="p-col-12">
                <div className="p-grid">
                  <div className="p-col-6">
                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleWirteHelp();
                        //this.handleEdithelp(this.state.help);
                        //this.handleUpdateBenefit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      if (this.state.mode === Mode.view && this.state.help.userId !== null) {
        return (
          <div className="p-col-12 p-md-8">
            <div className="card">
              <div className="p-col-12">
                <span className="p-float-label">
                  <InputText
                    id="in"
                    style={{ width: "70%" }}
                    value={this.state.help.title}
                    onChange={(e: any) => {
                      this.state.help.title = e.target.value;
                      this.setState({ help: this.state.help });
                    }}
                    readOnly
                  />
                  <label htmlFor="in">Title</label>
                </span>
              </div>
              <div className="p-col-12">
                <Panel header="문의 시간">
                  <p>{convertDate(this.state.help.regDate)}</p>
                </Panel>
              </div>
              <div className="p-col-12">
                <Panel header="내용">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.help.contents,
                    }}
                  ></div>
                </Panel>
              </div>

              <div className="p-col-12">
                {this.state.help && this.state.help.ref ? (
                  <Panel header="답변 시간">
                    <p>{convertDate(this.state.help.ref.date)}</p>
                  </Panel>
                ) : (
                  <></>
                )}
              </div>

              <div className="p-col-12">
                {this.state.help && this.state.help.ref.contents ? (
                  <Panel header="답변">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.help.ref.contents,
                      }}
                    ></div>
                    {/* <p>{convertDate(this.state.help.ref.date)}</p> */}
                  </Panel>
                ) : (
                  <></>
                )}
              </div>

              <div className="p-col-12">
                <div className="p-grid">
                  <div className="p-col-6"></div>
                  <div className="p-col-4">
                    <Button
                      label="삭제"
                      color="secondary"
                      onClick={() => {
                        this.handleDelHelp(this.state.help._id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>에이전트 고객센터</h4>
              </div>
              <div className="panel-body">
                <Button
                  label="글쓰기"
                  onClick={() => {
                    this.setState({ mode: Mode.wirte });
                  }}
                />
              </div>
              <div className="panel-body">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={(page) => {
                      // this.handleUserNote(this.state.dropdownItem, this.state.seach, page)
                    }}
                  ></CustomPagination>
                  <DataTable
                    value={items}
                    paginatorPosition="both"
                    rows={20}
                    sortOrder={-1}
                  >
                    <Column
                      style={{ textAlign: "left" }}
                      header="제목"
                      headerStyle={{
                        textAlign: "center",
                        background: "#27765c",
                        color: "#fff",
                        borderRight: "1px solid #d8d8d8",
                      }}
                      body={(rowData: any, column: any) => {
                        return (
                          <div style={{ color: rowData.color }}>
                            {" "}
                            {rowData.title}
                          </div>
                        );
                      }}
                    />

                    <Column
                      style={{ textAlign: "center" }}
                      headerStyle={{
                        textAlign: "center",
                        background: "#27765c",
                        color: "#fff",
                        borderRight: "1px solid #d8d8d8",
                      }}
                      header="아이디"
                      body={(rowData: any, column: any) => {
                        return (
                          <div
                            onClick={() => {
                              // OpenUserDetail(rowData.agentId);
                            }}
                            style={{ color: rowData.color }}
                          >
                            {rowData.agentId}
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center" }}
                      headerStyle={{
                        textAlign: "center",
                        background: "#27765c",
                        color: "#fff",
                        borderRight: "1px solid #d8d8d8",
                      }}
                      header="상태"
                      body={(rowData: any, column: any) => {
                        if (rowData.del == "y") {
                          return <>{HelpStatus(rowData.status)}</>;
                        }
                        return <>{HelpStatus(rowData.status)}</>;
                      }}
                    />
                    <Column
                      body={actionTemplate}
                      style={{ textAlign: "center", width: "8em" }}
                      headerStyle={{
                        textAlign: "center",
                        background: "#27765c",
                        color: "#fff",
                        borderRight: "1px solid #d8d8d8",
                      }}
                    />
                  </DataTable>
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={(page) => {}}
                  ></CustomPagination>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                  {RenderHelp()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { UserService } from "../service/user.service";
import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { numTomoeny, GetToday , ConvertCalendar} from "../utility/help";

interface MatchState {
  details: any;
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  page: any;
  maxCount: any;
  summary: any;
}

export class BetMatch extends Component<any, MatchState> {
  userService: UserService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      summary: {},
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,

      details: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" }
      ]
    };

    this.userService = new UserService();

    this.handlePageChange = this.handlePageChange.bind(this);

    this.get_mini_bet_list(0);
  }

  componentDidMount() {}

  get_mini_bet_list = (page: any) => {
    this.userService
      .agent_get_match_bet_list(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText
      )
      .then((data : any) => {
        for (let match of data.matchs) {
          this.userService
            .get_match_detail_by_betId(match._id)
            .then((detailData : any) => {
              this.state.details[detailData.betId] = detailData.detail;

              this.setState({
                details: this.state.details,
                maxCount: data.maxCount,
                summary: data.summary
              });
            });
        }

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px"
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_mini_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {

      return userTableValue.map((contact: any, i: any) => {
        return (
          <BetMatchTable
            bet={contact}
            key={contact._id}
            details={this.state.details[contact._id]}
            mode={"bet"}
          ></BetMatchTable>
        );
      });
    };

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>스포츠 배팅정보</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-inline">
                      <div className="form-group" style={{ paddingRight: "10px" }}>
                        <Calendar
                          placeholder="Begin"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.begin}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ begin: event.value })
                          }
                          locale={ConvertCalendar()}
                          />&nbsp;~&nbsp;
                          <Calendar
                          placeholder="End"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.end}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ end: event.value })
                          }
                          locale={ConvertCalendar()}
                          />
                      </div>
                      <div className="form-group" style={{ paddingRight: "10px" }}>
                        <span className="md-inputfield">
                          <InputText
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value
                              });
                            }}
                            className="form-control"
                          />
                        </span>
                      </div>
                      {/* Button Start */}
                      <Button
                            label="검색"
                            onClick={() => {
                              this.handlePageChange(0);
                            }}
                          />
                      {/* Button End */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <DataTable
                          style={{ padding: "0px" }}
                          value={this.state.summary}
                          paginatorPosition="both"
                          rows={20}
                        >
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="error" text="BET" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalBet)}</span>
                                </div>
                              );
                            }}
                          />
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="info" text="WIN" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalWin)}</span>
                                </div>
                              );
                            }}
                          />
                        </DataTable>
                </div>
              </div>
              <div className="panel-body">
                <CustomPagination
                    active={activePage}
                    take={10}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
              </div>
              <div className="panel-body">
                {RenderBet()}
              </div>
              <div className="panel-body">
                <CustomPagination
                      active={activePage}
                      take={10}
                      count={this.state.maxCount}
                      handlePageChange={this.handlePageChange}
                    ></CustomPagination>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

import React, { Component } from "react";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";
import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { SubPaging } from "../share/sub-paging";

interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
  level: any;
}

export class UserList extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regDate",
      sort: 1,
      maxCount: 1,
      level: 4,
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_list(page, find, search, sortType, sort, "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
          level: data.level,
        });
      });
  };

  GetConnectUserList = () => {
    this.userService
      .get_user_health_list(0, "", "", "", "regDate", "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
        });
      });
  };

  SortTable = (sortType: any, sort: any) => {
    console.log(sortType, sort)
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  UpdateUserBalacne = () => {
    this.userService.admin_get_update_all_balance().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "확인.",
          message: "30초이후에 자동 새로고침됨니다.",
          buttons: [
            {
              label: "예",
              onClick: () => {
                setTimeout(function() {
                  window.location.reload();
                }, 30000);
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "에러.",
          message: `해당기능은 ${data.time} 이후에 사용가능합니다.`,
          buttons: [
            {
              label: "예",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  MoveSel = (page: any) => {
    this.setState({ page: page });
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>회원관리</h4>
              </div>
              <div className="panel-body">
                <HelpBox
                  SearchData={this.SearchData}
                  GetConnectUserList={this.GetConnectUserList}
                ></HelpBox>
              </div>
              <div className="panel-body">
                <Button
                  label="유저 벨런스 업데이트"
                  onClick={() => {
                    this.UpdateUserBalacne();
                  }}
                />
              </div>
              <div className="panel-body">
                <SubPaging
                  activePage={this.state.page}
                  maxCount={this.state.maxCount}
                  limit={20}
                  MoveSel={(page: number) => {
                    this.MoveSel(page);
                  }}
                ></SubPaging>
              </div>
              <div className="panel-body">
                <UserTable
                  level={this.state.level}
                  userTableValue={this.state.userTableValue}
                  // SortTable={this.SortTable}
                  mode="used"
                  handleUseStauseChange={() => {}}
                ></UserTable>
              </div>
              <div className="panel-body">
                <SubPaging
                  activePage={this.state.page}
                  maxCount={this.state.maxCount}
                  limit={20}
                  MoveSel={(page: number) => {
                    this.MoveSel(page);
                  }}
                ></SubPaging>
              </div>
            </div>
          </div>
        </div>
        \
      </div>
    );
  }
}

import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AgentService } from "../service/agent.service";
import { SubPaging } from "../share/sub-paging";


import {
  numTomoeny,
  convertDate,
  convertAgentLogText,
  convertAgentBalanceText,
  OpenUserDetail,
  OpenAgentDetail
} from "../utility/help";

interface AgentState {
  logs: any;
  activePage: any;
  maxCount: any;
}

export class AgentBalanceLog extends Component<any, AgentState> {
  AgentService: AgentService;
  constructor(props: any) {
    super(props);

    this.state = {
      logs: [],
      maxCount: 1,
      activePage: 1
    };

    this.AgentService = new AgentService();
  }

  componentDidMount() {
    this.handleAgentBalanceLog(0);
  }

  handleAgentBalanceLog = (page: any) => {
    this.AgentService.agent_get_agnet_balance_log(page).then((s: any) => {
      this.setState({
        logs: s.logs,
        maxCount: s.maxCount
      });
    });
  };
  handleMoveSel = (page: any) => {
    this.setState({ activePage: page });
    this.handleAgentBalanceLog(page);
  };

  render = () => {
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>에이전트 벨런스</h4>
              </div>
              <div className="panel-body">
              <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.logs}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타입"
                    body={(agent: any) => {
                      return <div>{convertAgentLogText(agent.logType)}</div>;
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="벨런스타입"
                    body={(agent: any) => {
                      return (
                        <div>{convertAgentBalanceText(agent.balanceType)}</div>
                      );
                    }}
                    field="point"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="에이전트"
                    body={(agent: any) => {
                      console.log(agent)
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agentId);
                          }}
                        >
                          {agent.agentId}
                        </div>
                      );
                    }}
                    field="level"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="유저 아이디"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenUserDetail(agent.userId);
                          }}
                        >
                          {agent.userId}
                        </div>
                      );
                    }}
                    field="nick"

                    // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="충/환전/롤링/관리자"
                    body={(agent: any) => {
                      return agent.balance;
                    }}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="남은금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.afterbalance);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="등록일"
                    body={(agent: any) => {
                      return convertDate(agent.regDate);
                    }}
                    field="agent_tree"
                  />
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={page => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  };
}

import React, { Component } from "react";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";

interface Alert {
  type: string;
  className: string;
  btnLabel: string;
  title: string;
  message: string;
  call: (par: any) => any;
}

interface message {
  label: string;
  onClick: () => void;
}

export class AlertYesNo extends Component<Alert> {
  constructor(props: Alert) {
    super(props);
  }

  render() {
    const type = this.props.type;
    const className = this.props.className;
    const btnLabel = this.props.btnLabel;
    const title = this.props.title;
    const message = this.props.message;
    const call = this.props.call;

    let button: Array<{
      label: string;
      onClick: () => void;
    }> = [
      {
        label: "예",
        onClick: () => {
          if (call !== undefined) call(type);
        }
      },
      {
        label: "아니요",
        onClick: () => {}
      }
    ];
    return (
      <div className="p-col-12 p-md-4">
        <span className="md-inputfield">
          <Button
            label={btnLabel}
            className={className}
            onClick={() => {
              confirmAlert({
                title: title,
                message: message,
                buttons: button
              });
            }}
          />
        </span>
      </div>
    );
  }
}

import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { UserService } from "../service/user.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { CustomPagination } from "../share/custom-pagination";
import { ConvertCalendar, GetToday } from "../utility/help";

interface WithdrawsState {
  balances: any;
  withdraws: any;
  activePage: any;
  field: any;
  begin: any;
  end: any;
  total: any;
  maxCount: number;
  searchText: any;
  findText: any;
  dropdownFind: any;
  cities: any;
  viewType: any;
  selectViewType: any;
  selectBalanceType: any;
  balanceType: any;
  level: any;
}

export class DeporsitAndWithdraw extends Component<any, WithdrawsState> {
  userService: UserService = new UserService();
  constructor(props: any) {
    super(props);

    let today = GetToday();
    this.state = {
      maxCount: 1,
      activePage: 1,
      balances: 0,
      withdraws: [],
      field: "",
      begin: today.begin,
      end: today.end,
      total: [],
      level: 1,
      searchText: "",
      findText: "",
      dropdownFind: "",
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "userId" },
        { label: "이름", value: "bankOwner" },
        { label: "에이전트", value: "agentId" },
      ],

      selectViewType: "all",
      selectBalanceType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
        // { label: "에이전트=> 에이전트", value: "agent_to_agent" },
      ],
      balanceType: [
        { label: "모두 보기", value: "all" },
        { label: "유저", value: "user" },
        { label: "에이전트", value: "agent" },
        { label: "센터", value: "center" },
      ],
    };

    this.get_deposit_and_withdraw(0, "all", this.state.selectBalanceType);
  }

  componentDidMount() {}

  handlePageChange = (page: any) => {
    this.setState({ activePage: page });
    // this.get_deposit_and_withdraw(page, this.state.selectViewType);
    this.get_deposit_and_withdraw(
      page,
      this.state.selectViewType,
      this.state.selectBalanceType
    );
  };
  // active={activePage}
  // take={20}
  // count={this.state.maxCount}
  // handlePageChange={this.handlePageChange}

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find((s: any) => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };

  get_deposit_and_withdraw = async (
    page: any,
    viewType: any,
    selectBalanceType: any
  ) => {
    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;
    let findText = this.state.dropdownFind;

    let data = await this.userService.get_deposit_and_withdraw_total_balance(
      page,
      begin.toLocaleString("sv-SE"),
      end.toLocaleString("sv-SE"),
      searchText,
      findText,
      viewType,
      selectBalanceType
    );
    this.setState({
      maxCount: data.maxCount,
      balances: data.balances,
      total: data.total,
      searchText: searchText,
      findText: findText,
      // viewType: viewType
    });
  };

  render() {
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>충전/환전 관리</h4>
              </div>
              <div className="panel-body">
                <div className="form-inline">
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.begin)}
                      onChange={(e: any) =>
                        this.setState({
                          begin: e.value.toLocaleString("sv-SE"),
                        })
                      }
                      showWeek={false}
                      locale={ConvertCalendar()}
                      showTime
                    />
                    &nbsp;~&nbsp;
                    <Calendar
                      placeholder="End"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      value={new Date(this.state.end)}
                      onChange={(e: any) =>
                        this.setState({ end: e.value.toLocaleString("sv-SE") })
                      }
                      showWeek={false}
                      locale={ConvertCalendar()}
                      showTime
                    />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <Dropdown
                      style={{ width: 100 }}
                      options={this.state.cities}
                      value={this.state.dropdownFind}
                      onChange={(event) =>
                        this.setState({ dropdownFind: event.value })
                      }
                      autoWidth={true}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <span className="md-inputfield">
                      <InputText
                        onChange={(event: any) => {
                          this.setState({
                            searchText: event.target.value,
                          });
                        }}
                        className="form-control"
                      />
                      <label>검색</label>
                    </span>
                  </div>
                  {/* Button Start */}
                  <Button
                    label="검색"
                    onClick={() => {
                      this.get_deposit_and_withdraw(
                        0,
                        this.state.selectViewType,
                        this.state.selectBalanceType
                      );
                    }}
                  />
                  {/* Button End */}
                </div>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <SelectButton
                    value={this.state.selectViewType}
                    options={this.state.viewType}
                    onChange={(event) => {
                      this.setState({ selectViewType: event.value });
                      this.get_deposit_and_withdraw(
                        0,
                        event.value,
                        this.state.selectBalanceType
                      );
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <SelectButton
                    value={this.state.selectBalanceType}
                    options={this.state.balanceType}
                    onChange={(event) => {
                      // this.state.selectViewType;
                      this.setState({ selectBalanceType: event.value });
                      this.get_deposit_and_withdraw(
                        0,
                        this.state.selectViewType,
                        event.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
              <div className="panel-body">
                <BalanceTable
                  mode="deporsitAndwithdraw"
                  balances={this.state.balances}
                  level={this.state.level}
                  OnEditItem={this.OnEditItem}
                  OnSelectItem={this.OnSelectItem}
                ></BalanceTable>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

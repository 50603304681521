import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import {
  numTomoeny,
  convertAgentBenefitBalanceText,
  ConvertCalendar,
  GetYesterOnlyDay,
} from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";

interface Props {
  agentId?: string;
}

interface BalanceState {
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;
  options: any[];
  options_type: string;
  begin: any;
  end: any;

  agentInfo: any[];

  level: number;
  totalBalance: any;
  viewType: any;

}

export class CalculateSubAgent extends Component<Props, BalanceState> {
  balanceService: BalanceService = new BalanceService();
  agentService: AgentService = new AgentService();

  constructor(props: Props) {
    super(props);

    let today = GetYesterOnlyDay();

    this.state = {
      begin: today.begin,
      end: today.end,
      findText: "",
      searchText: "",
      selectItem: {},

      options: [
        { label: "입출금/통계", value: "balance" },
        { label: "총판/입출", value: "agentGive" },
      ],

      options_type: "balance",

      select: [{ label: "선택", value: null }],

      agentInfo: [],
      level: 1,
      totalBalance: {},
      viewType: "balance",

    };

    this.handlegetSubAgentCalculate(this.props.agentId, today.begin, today.end);
    //this.handleGetAgentBenefit();
  }

  // handleGetAgentBenefit = () => {
  //   this.agentService.agent_get_benefits().then((s) => {
  //     if (s.status == "success") {
  //       this.setState({ benefits: s.benefits ?? [] });
  //     }
  //   });
  // };

  handlegetSubAgentCalculate(agentId, begin: any, end: any) {
    this.balanceService
      .admin_get_sub_agent(
        agentId,
        begin.toLocaleString("sv-SE"),
        end.toLocaleString("sv-SE")
      )
      .then((data) => {
        if (data.status == "success") {
          let totalBalance = {};
          for (let balances of Object.values(data.agentCombine) as any) {
            for (let key of Object.keys(balances as [])) {
              if (typeof balances[key] === "number") {
                if (totalBalance[key] == null) {
                  totalBalance[key] = 0;
                }
                totalBalance[key] += Number(balances[key] as Number);
              } else if (
                typeof balances[key] === "object" &&
                balances[key] != null
              ) {
                for (let key2 of Object.keys(balances[key] as [])) {
                  if (typeof balances[key][key2] === "number") {
                    if (totalBalance[`${key}.${key2}`] == null) {
                      totalBalance[`${key}.${key2}`] = 0;
                    }

                    totalBalance[`${key}.${key2}`] += Number(
                      balances[key][key2] as Number
                    );
                  }
                }
              }
            }
          }

          const combin = Object.values(data.agentCombine);
          combin.sort(function(a: any, b: any) {
            if (a.agent_tree < b.agent_tree) return -1;
            if (b.agent_tree < a.agent_tree) return 1;
            return 0;
          });

          this.setState({
            agentInfo: combin,
            level: data.level,
            totalBalance: totalBalance,
          });
        }
      });
  }

  componentDidMount() {}

  render() {
    const rowClass = (data) => {
      if (data.level === 1) return { "row-level1": data.level === 1 };
      if (data.level === 2) return { "row-level2": data.level === 2 };
      if (data.level === 3) return { "row-level3": data.level === 3 };

      return { "row-level4": data.level === 4 };
    };

    

    const RenderCasino = () =>{
      return (
        <div className="panel-body">
        <DataTable
          paginatorPosition="both"
          scrollable={true}
          value={this.state.agentInfo}
          rows={999}
          sortOrder={-1}
          style={{ padding: "0px" }}
          scrollHeight="600px"
          rowClassName={rowClass}
        >
          {this.state.level <= 1 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="본사"
              body={(rowData: any, column: any) => {
                if (rowData.level === 1)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}
          {this.state.level <= 2 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="부본사"
              body={(rowData: any, column: any) => {
                if (rowData.level === 2)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}
          {this.state.level <= 3 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="총판"
              body={(rowData: any, column: any) => {
                if (rowData.level === 3)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}

          {this.state.level <= 4 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="대리점"
              body={(rowData: any, column: any) => {
                if (rowData.level === 4)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}



                                    <Column
                                            headerStyle={{
                                              textAlign: "center",
                                              background: "#27765c",
                                              width: "80px",
                                              color: "#fff",
                                              borderRight: "1px solid #d8d8d8",
                                            }} style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>Casino Bet</div><div>Casino Win</div></div>} 
                                        //header={<div>Casino Bet</div>} 
                                        body={(rowData: any, column: any) => {
                                          return <div>
                                              <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                              <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                          </div>;
                                        }}
                                    />

                                    <Column
                                          headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}   style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>총회원 루징</div></div>}
                                        body={(rowData: any, column: any) => {
                                          const total = (rowData.totalCasinoBet - rowData.totalCasinoWin)
                                          return <div style={{ color: total > 0 ? 'red' : 'blue' }}>{numTomoeny(total)}</div>
                                        }}
                                    />




                                    <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}  style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>정산금</div><div>루징프로테이지%</div></div>}
                                        body={(rowData: any, column: any) => {
                                            {/* 정산 */ }


                                            const total = (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw +
                                                (rowData.totalYDInBalance - rowData.totalInBalance))
                                                - (Number.isNaN((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling) ? 0 : (rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)
                                                - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)
                                            ) / 100)
                                                * rowData.benefitLosing

                                            return <div>
                                                <div style={{ color: total > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny(total)}</div>

                                                <div>{rowData.benefitLosing} %</div>
                                            </div>;


                                        }}
                                    />



                                    <Column
                                         headerStyle={{
                                          textAlign: "center",
                                          background: "#27765c",
                                          width: "80px",
                                          color: "#fff",
                                          borderRight: "1px solid #d8d8d8",
                                        }}
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>유저 카지노 롤링</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                                            </div>;
                                        }}
                                    />



        </DataTable>
      </div>
      )
    }

    const RenderTotal = () =>{
      return (
        <div className="panel-body">
        <DataTable
          paginatorPosition="both"
          scrollable={true}
          value={this.state.agentInfo}
          rows={999}
          sortOrder={-1}
          style={{ padding: "0px" }}
          scrollHeight="600px"
          rowClassName={rowClass}
        >
          {this.state.level <= 1 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="본사"
              body={(rowData: any, column: any) => {
                if (rowData.level === 1)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}
          {this.state.level <= 2 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="부본사"
              body={(rowData: any, column: any) => {
                if (rowData.level === 2)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}
          {this.state.level <= 3 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="총판"
              body={(rowData: any, column: any) => {
                if (rowData.level === 3)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}

          {this.state.level <= 4 && (
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                width: "80px",
              }}
              headerStyle={{
                textAlign: "center",
                background: "#27765c",
                width: "80px",
                color: "#fff",
                borderRight: "1px solid #d8d8d8",
              }}
              header="대리점"
              body={(rowData: any, column: any) => {
                if (rowData.level === 4)
                  return (
                    <div>
                      <div>{rowData.agent}</div>
                      <div>{rowData.nick}</div>
                    </div>
                  );
                return <></>;
              }}
            />
          )}



                                      <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>

                                            </div>;
                                        }}
                                    />


                                    <Column
                               headerStyle={{
                                textAlign: "center",
                                background: "#27765c",
                                width: "80px",
                                color: "#fff",
                                borderRight: "1px solid #d8d8d8",
                              }}              style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                         headerStyle={{
                                          textAlign: "center",
                                          background: "#27765c",
                                          width: "80px",
                                          color: "#fff",
                                          borderRight: "1px solid #d8d8d8",
                                        }}    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                            headerStyle={{
                                              textAlign: "center",
                                              background: "#27765c",
                                              width: "80px",
                                              color: "#fff",
                                              borderRight: "1px solid #d8d8d8",
                                            }} style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        // header={<div><div>Casino Bet</div><div>Casino Win</div></div>} 
                                        header={<div>Casino Bet</div>} 
                                        body={(rowData: any, column: any) => {
                                            return <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                        }}
                                    />


                                    <Column
                                            headerStyle={{
                                              textAlign: "center",
                                              background: "#27765c",
                                              width: "80px",
                                              color: "#fff",
                                              borderRight: "1px solid #d8d8d8",
                                            }} style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>Slot Bet</div>} 
                                        // header={<div><div>Slot Bet</div><div>Slot Win</div></div>} 
                                        body={(rowData: any, column: any) => {
                                            return <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                {/* <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div> */}

                                        }}
                                    />


                                    <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}  style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>총회원 루징</div>} 
                                        body={(rowData: any, column: any) => {
                                            // 총회원 루징


                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny((rowData.totalDeposit + rowData.totalGiveDeposit
                                                    + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw) + (rowData.totalYDInBalance - rowData.totalInBalance)
                                                )}</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                          headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}   style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>슬롯 롤링</div></div>}
                                        body={(rowData: any, column: any) => {

                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                          headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}   style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>카지노 롤링</div></div>}
                                        body={(rowData: any, column: any) => {

                                            return <div>
                                                <div style={{ color: '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                            </div>;
                                        }}
                                    />




                                    <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}  style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 보유</div><div>전일 보유</div></div>} 
                                        body={(rowData: any, column: any) => {
                                            {/* 유저보유 */ }

                                            return <div style={{ color: '#2196f3' }}>
                                                <div>{numTomoeny(rowData.totalInBalance)}</div>
                                                <div>{numTomoeny(rowData.totalYDInBalance)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}  style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>정산금</div><div>루징프로테이지%</div></div>}
                                        body={(rowData: any, column: any) => {
                                            {/* 정산 */ }


                                            const total = (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw +
                                                (rowData.totalYDInBalance - rowData.totalInBalance))
                                                - (Number.isNaN((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling) ? 0 : (rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)
                                                - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)
                                            ) / 100)
                                                * rowData.benefitLosing

                                            return <div>
                                                <div style={{ color: total > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny(total)}</div>

                                                <div>{rowData.benefitLosing} %</div>
                                            </div>;


                                        }}
                                    />


                                    <Column
                                           headerStyle={{
                                            textAlign: "center",
                                            background: "#27765c",
                                            width: "80px",
                                            color: "#fff",
                                            borderRight: "1px solid #d8d8d8",
                                          }}  style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>에이전트 충전</div><div>에이전트 환전</div></div>} 
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAgentChangeBalance)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAgentExchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                            headerStyle={{
                                              textAlign: "center",
                                              background: "#27765c",
                                              width: "80px",
                                              color: "#fff",
                                              borderRight: "1px solid #d8d8d8",
                                            }}
                                         style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>롤롱케쉬전환</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                         headerStyle={{
                                          textAlign: "center",
                                          background: "#27765c",
                                          width: "80px",
                                          color: "#fff",
                                          borderRight: "1px solid #d8d8d8",
                                        }}
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>유저 슬롯 롤링</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div>{numTomoeny(rowData.totalUserRollingSlot)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                         headerStyle={{
                                          textAlign: "center",
                                          background: "#27765c",
                                          width: "80px",
                                          color: "#fff",
                                          borderRight: "1px solid #d8d8d8",
                                        }}
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>유저 카지노 롤링</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                                            </div>;
                                        }}
                                    />



        </DataTable>
      </div>
      )
    }

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>{this.props.agentId}</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline">
                    <div
                      className="form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.begin)}
                        showWeek={false}
                        hourFormat="24"
                        onChange={(e: any) => {
                          this.setState({ begin: e.value });
                        }}
                        locale={ConvertCalendar()}
                      />
                      &nbsp;~&nbsp;
                      <Calendar
                        placeholder="End"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.end)}
                        showWeek={false}
                        hourFormat="24"
                        onChange={(e: any) => {
                          this.setState({ end: e.value });
                        }}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    {/* Button Start */}
                    <Button
                      label="검색"
                      onClick={() => {
                        this.handlegetSubAgentCalculate(
                          this.props.agentId,
                          this.state.begin,
                          this.state.end
                        );
                      }}
                    />
                    {/* Button End */}
                  </div>

                  <div className="p-col-12 p-md-4">
                                            <SelectButton
                                                value={this.state.viewType}
                                                options={
                                                    [
                                                        { label: "입출금 정산", value: "balance" },
                                                        { label: "카지노 루징 정산", value: "casino" },
                                                    ]
                                                }
                                                onChange={(event) => {
                                                  if(event.target.value == null){ return }
                                                  this.setState({ viewType: event.target.value });
                                                }}
                                            />

                                        </div>
                </div>
              </div>


              {this.state.viewType === 'balance' && RenderTotal()} 
              {this.state.viewType === 'casino' && RenderCasino()}
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { AgentService } from "../service/agent.service";
import { numTomoeny, convertAgentBenefitBalanceText } from "../utility/help";

interface NewAgent {
  parentId: string;
  agent: string;
  nick: string;
  pass: string;
  phone: string;
  bankname: string;
  banknum: string;
  bankowner: string;

  items: NewAgent[];
}

interface State {
  agent: any;
  balance: any;
  benefits : any;
}

export class Balance extends Component<any, State> {
  agentService: AgentService = new AgentService();
  constructor(props: any) {
    super(props);

    this.state = {
      agent: {},
      balance: { point: 0, rolling: 0, losing: 0, balance: 0 , chBalance: 0},
      benefits : []
    };



    this.handleGetAgentBalance();
    this.handleGetAgentBenefit();
  }

  componentDidMount() {

  }

  handleGetAgentBalance = () => {
    this.agentService.agent_get_agent_info().then(s => {
      if (s.status == "success") {
        this.setState({ agent: s.agent });
      }
    });
  };

  
  handleGetAgentBenefit = () => {
    this.agentService.agent_get_benefits().then(s => {
      if (s.status == "success") {
        this.setState({ benefits: s.benefits });
      }
    });
  };

  

  handleChangeBalance = (balance : any, ex: any) => {

    if (balance.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 알을 충전신청합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_change_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } 
  };

  
  handleExchangeBalance = (balance, ex) => {
    if (balance % 1000 != 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {}
          }
        ]
      });
      return;
    }

    if (balance.type != "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이전트 루징/포인트/롤링을 벨런스로 변환 신청을 합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } else {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    }
  };


  render() {
    let agent = this.state.agent;
    let balance = this.state.balance;

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>알 충전신청</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <label htmlFor="input">
                          {numTomoeny(Number(agent.balance))}
                        </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <InputText
                          id="input"
                          value={balance.chBalance}
                          onChange={(even: any) => {
                            balance.chBalance = even.target.value;

                            this.setState({ balance: balance });
                          }}
                          className="form-control"
                        />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <Button
                          label="확인"
                          className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "balance";

                            this.handleChangeBalance(balance.chBalance, balance);
                          }}
                        />
                </div>
              </div>
            </div>

            <div className="panel">
              <div className="panel-heading">
                <h4>보유중 롤링</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                  <label htmlFor="input">
                          {numTomoeny(Number(agent.rolling))}
                        </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <InputText
                          id="input"
                          value={balance.rolling}
                          onChange={(even: any) => {
                            balance.rolling = even.target.value;

                            this.setState({ balance: balance });
                          }}
                          className="form-control"
                        />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <Button
                        label="롤링 => 벨런스"
                        className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "rolling";

                            this.handleExchangeBalance(balance.rolling, balance);
                          }}
                        />
                </div>
              </div>
            </div>

            <div className="panel">
              <div className="panel-heading">
                <h4>보유중 벨런스(환전금액)</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <label htmlFor="input">
                          {numTomoeny(Number(agent.balance))}
                        </label>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <InputText
                          id="input"
                          value={balance.balance}
                          onChange={(even: any) => {
                            balance.balance = even.target.value;

                            this.setState({ balance: balance });
                          }}
                          className="form-control"
                        />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <Button
                        label="환전 하기"
                        className="p-button-warning"
                          onClick={(even: any) => {
                            balance.type = "balance";
                            this.handleExchangeBalance(balance.balance, balance);
                          }}
                        />
                </div>
              </div>
            </div>

            <div className="panel">
              <div className="panel-heading">
                <h4>에이전트 벨런스</h4>
              </div>
              
              {
                    Object.values(this.state.benefits).map((benefit : any) => {
                      return (

                  <div className="panel-body">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <label htmlFor="input">{convertAgentBenefitBalanceText(benefit.benefit_type)}</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <label htmlFor="input">
                          {benefit.percent}
                          </label>
                    </div>
                  </div>
                      )
                    })
                  }
                
              
            </div>
          </div>
        </div>


      </div>
    );
  }
}

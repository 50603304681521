
export default (name) => {

    // console.log('SoundPlay')
    // console.log(document.getElementById("userReg"))

    // console.log(document.getElementById("userReg").innerHTM)
    document.getElementById(name).innerHTML = "1"

}

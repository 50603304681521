import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  numTomoeny,
  GetToday,
  convertDate,
  converBalanceStatus,
  convertLogText,
  getDepositColor,
  getWithdrawColor,
  convertBalanceType,
  convertMessage
} from "../../utility/help";

interface BalanceProps {
  logs: any;
}

export class LogAgentBalanceTable extends Component<any, BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
  }

  render() {
    let logs = this.props.logs;
    return (
      <DataTable
        value={logs}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >

        
        <Column
          
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
         
          header="아이디"
          body={(rowData: any, column: any) => {
            return rowData.userId;
          }}
        />

        <Column
          
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header={"일시"}
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.regDate)}</div>;
          }}
          field="depositdate"
        />     

        <Column
         style={{ textAlign: "center", width: "300px" , borderRight: '1px solid #d8d8d8'}}
         headerStyle={{ textAlign: "center",width: "300px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
         
         header={"내용"}
          body={(rowData: any, column: any) => {
            return <div><span dangerouslySetInnerHTML={{ __html: convertBalanceType(rowData.agentId, rowData.userId, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>  <span dangerouslySetInnerHTML={{ __html: convertMessage(rowData.logType, rowData.simplememo) }} ></span>  </div>
         }}
        />

        <Column
          
          
          style={{ textAlign: "right", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
         
          header="벨런스 +-"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: getWithdrawColor() }}>
                {numTomoeny(rowData.balance)}
              </div>
            );
          }}
        />

        <Column

         style={{ textAlign: "right", width: "120px" , borderRight: '1px solid #d8d8d8'}}
         headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
         
          header="남은 벨런스"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.afterBalance)}</div>;
            }}
          />
{/* 
        <Column
          style={{ textAlign: "center", width: "180px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "180px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"에이전트"}
          body={(rowData: any, column: any) => {
            return <div>{rowData.agent_tree}</div>;
          }}
        />
    


        <Column

          style={{ textAlign: "center", width: "180px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "180px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header="총판"
          body={(rowData: any, column: any) => {
            return rowData.agent;
          }}
        /> */}

        {/* 
        <Column
          style={{ textAlign: "center" }}
          header={"CODE"}
          body={(rowData, column) => {
            return <div>{rowData._id}</div>;
          }}
          field="depositdate"
        /> */}
      </DataTable>
    );
  }
}

import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { BalanceService } from "../service/balance.service";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

interface BalanceState {
  logs: any;
  findText: any;
  searchText: any;
  maxCount: any;
  activePage: number;
  select: any;
  selectItem: any;
}

export class BalanceLog extends Component<any, BalanceState> {
  balanceService: BalanceService;
  constructor(props: any) {
    super(props);

    this.state = {
      logs: [],
      findText: "",
      searchText: "",
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      select: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" }
      ]
    };
    this.handlePageChange = this.handlePageChange.bind(this);

    this.balanceService = new BalanceService();
  }

  handlePageChange(page: number) {

  }

  componentDidMount() {
    this.handlePageChange(1);
  }

  render() {
    let logs = this.state.logs;
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>벨런스 기록</h4>
              </div>
              <div className="panel-body">
                  <div className="form-inline">
                    <div className="form-group" style={{ paddingRight: "10px" }}>
                        <Dropdown
                            options={this.state.select}
                            value={this.state.findText}
                            onChange={(event: any) =>
                              this.setState({
                                findText: event.value
                              })
                            }
                            autoWidth={false}
                          />
                    </div>
                    <div className="form-group" style={{ paddingRight: "10px" }}>
                        <span className="md-inputfield">
                          <InputText
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value
                              });
                            }}
                            className="form-control"
                          />
                          <label>검색</label>
                        </span>
                    </div>
                    {/* Button Start */}
                    <Button
                            label="검색"
                            onClick={() => {
                              this.handlePageChange(0);
                            }}
                          />
                    {/* Button End */}
                  </div>
              </div>
              <div className="panel-body">
              <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              <div className="panel-body">
                <LogBalanceTable logs={logs}></LogBalanceTable>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    );
  }
}

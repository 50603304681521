import axios from "axios";

import { AxiosMag } from "./axios.service";

export class BalanceService {
  agent_deposit_to_user = async (balance: number, id: string, code: number) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_deposit_to_user", {
      balance: balance,
      id: id,
      code : code
    });
  };

  agent_withdraw_to_user = async (balance: number, id: string, code: number) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_withdraw_to_user_for_main_service", {
      balance: balance,
      id: id,
      code : code,
    });
  };

  agent_balance_log = async (
    skip: number,
    findText: string,
    searchText: string,
    begin: string,
    end: string,
    view : string
  ) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_balance_log", {
      skip,
      findText,
      searchText,
      begin,
      end,
      view
    });
  };

  agent_deposit_to_agent = async (balance: number, takeId: string) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_deposit_to_agent", {
      balance: balance,
      takeId: takeId,
    });
  };

  
  agent_withdrawt_to_agent = async (balance: number, takeId: string) => {
    return await AxiosMag.SendMessageByPost("/balance/agent_withdrawt_to_agent", {
      balance: balance,
      takeId: takeId,
    });
  };

    
  admin_get_sub_agent = async (agentId: string, begin: string, end: string) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_get_sub_agent_time", {
      agentId: agentId,
      begin: begin,
      end: end,
    });
  };


  

  
}

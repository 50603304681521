import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
} from "../../utility/help";

interface SlotProps {
  mode: any;
  bets: any;
  // OnEditItem: any;
  // OnSelectItem: any;
  // const mode = this.props.mode;
  // const bets = this.props.bets;
  // const OnEditItem = this.props.OnEditItem;
  // const OnSelectItem = this.props.OnSelectItem;
}

export class BetSlotTable extends Component<SlotProps> {
  constructor(props: SlotProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bets;

    return (
      <DataTable
        value={bets}
        paginatorPosition="both"
        rows={9999}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header="아이디"
          field="id"
          // body={(rowData: any, column: any) => {
          //   return <div style={{ color: rowData.color }}>{rowData.id}</div>;
          // }}
          body={(rowData: any, column: any) => {
            if(rowData.id != null){
            return <div onClick={() => {}}>{rowData.id}({rowData.agent})</div>;
            }

            return <div onClick={() => {}}>{rowData.userId}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="bet"
          header="배팅 시간"
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.betTime)}</div>;
          }}
        />

        {/* <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="win"
          header="WIN/LOSE"
          body={(rowData: any, column: any) => {
            return <div>{rowData.transType}</div>;
          }}
        /> */}

        <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="win"
          header="배팅"
          body={(rowData: any, column: any) => {

            return <div>{numTomoeny(rowData.amountBet)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="win"
          header="이긴금액"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.amountWin)}</div>;
          }}
        />
        
        <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="win"
          header="롤링"
          body={(rowData: any, column: any) => {
            if(rowData.giveBalance == null || rowData.percent == null){
              return <></>
            }
            return <div>{numTomoeny(rowData.giveBalance)} ({(rowData.percent).toFixed(3)}%)</div>;
          }}
        />
        
        {/* <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          field="win"
          header="보유"
          body={(rowData: any, column: any) => {
        
            return <div>{numTomoeny(rowData.afterBalance)}</div>;
          }}
        /> */}

        <Column
          style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header="총판"
          body={(rowData: any, column: any) => {
            return <div>{rowData.agentTree}</div>;
          }}
        />

        
        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header="게임명"
          body={(rowData: any, column: any) => {
          return <div>{rowData.tableName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
          headerStyle={{ textAlign: "center",width: "220px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
          header="회사명"
          body={(rowData: any, column: any) => {
          return <div>{rowData.thirdParty}</div>;
          }}
        />
      </DataTable>
    );
  };
}

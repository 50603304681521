import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { OverlayPanel } from 'primereact/overlaypanel';
import { AgentService } from "../service/agent.service";
import cookie from "react-cookies";
import { AxiosMag } from "../service/axios.service";

interface State {
    oldPW: any;
    newPW1: any;
    newPW2: any;
    admin : any;

    changeBalance : number;

    benefit: any;
}

export class AgentPW extends Component<any, State> {
  agentService: AgentService = new AgentService();


  chageBalance:any = React.createRef();
  exchageBalance:any = React.createRef();
  changeBalance:any = React.createRef();


  constructor(props: any) {
    super(props);

    this.state = {
        oldPW: '',
        newPW1: '',
        newPW2: '',
        admin : {},
        benefit: [],
        changeBalance : 0,
    };
  }

  componentDidMount() {

    let session = cookie.load("session");
    AxiosMag.SetSession(session);

  
    this.agentService.agent_check_health().then((data : any) => {
      if (data.status == "success") {

        this.setState({ benefit : data.benefit});

      }
    });

  }

  
  
  handleDoWithdraw() {
  
  }

  
  handleSave = () => {

    if (
        this.state.oldPW.length < 4
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    if (
        this.state.newPW1.length < 4
        
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    this.agentService.admin_change_to_password(this.state.oldPW, this.state.newPW1 ).then(data =>{

        if (data.status == "success") {
         confirmAlert({
           title: "에이전트",
           message: "에이전트 비밀번호를 변경하였습니다",
           buttons: [
             {
               label: "확인",
               onClick: () => {
               },
             },
           
           ],
         });
        }
        else{
            confirmAlert({
                title: "에이전트",
                message: "기존 비밀번호를 확인해주세요.",
                buttons: [
                  {
                    label: "확인",
                    onClick: () => {
                    },
                  },
              
                ],
              });
        }
    })
  };

  
  
  handleExchangeBalance = (balance, ex) => {
    
    if (Number(balance) % 1000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

  

    if (ex.type == "rolling") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이젼트 포인트 캐쉬전환이 완료되었습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } else   if (ex.type == "balance") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
                else if (s.status == "balance") {
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: "에이젼트 보유금액이 환전신청금액보다 작습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {}
                      }
                    ]
                  });
                }
                
              });
            }
          }
        ]
      });
    }
  };
  

  handleChangeBalance = (balance : any, ex: any) => {

    if (Number(balance) % 10000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "충전할 금액은  1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
  

    confirmAlert({
      title: "에이전트 벨런스",
      message: "에이전트 충전신청합니다.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.agentService.agent_change_balance(ex).then(s => {
              if (s.status == "success") {
                window.location.reload();
              }
            });
          }
        }
      ]
    });
  };


  
  render() {
  

    return (
      <div>

          <OverlayPanel ref={this.chageBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
          
            <div className="md-inputfield">
              <span className="p-float-label" style={{padding : '20px'}}>
                    <div className="card-title">충전신청</div>

                      <div className="p-md-12">
                        <div className="p-grid form-group">
                          <div className="p-col-12 p-md-1">
                            <label htmlFor="input">
                            </label>
                          </div>
                          <div className="p-col-12 p-md-4">
                            <InputText
                              id="input"
                              value={this.state.changeBalance}
                              onChange={(even: any) => {
                                this.setState({ changeBalance: even.target.value });
                              }}
                            />
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Button
                              label="확인"
                              className="p-button-warning"
                              style={{color : 'black'}}
                              onClick={(even: any) => {
                                this.handleChangeBalance(this.state.changeBalance, {chBalance : this.state.changeBalance , type  : "balance"});
                              }}
                            />
                          </div>

                        </div>
                      </div>

              </span>
          </div> 
          </OverlayPanel>
          

          <OverlayPanel ref={this.exchageBalance}  id="overlay_panel" style={{width: '500px'}} showCloseIcon={false}>
            
            <div className="md-inputfield">
              <span className="p-float-label" style={{padding : '20px'}}>
                    <div className="card-title">환전신청</div>

                      <div className="p-md-12">
                        <div className="p-grid form-group">
                          <div className="p-col-12 p-md-1">
                            <label htmlFor="input">
                            </label>
                          </div>
                          <div className="p-col-12 p-md-4">
                            <InputText
                              id="input"
                              value={this.state.changeBalance}
                              onChange={(even: any) => {
                                this.setState({ changeBalance: even.target.value });
                              }}
                            />
                          </div>
                          <div className="p-col-12 p-md-5">
                            <Button
                              label="확인"
                              className="p-button-warning"
                              style={{color : 'black'}}
                              onClick={(even: any) => {
                                this.handleExchangeBalance(this.state.changeBalance, {balance : this.state.changeBalance , type  : "balance"});
                                // this.handleExchangeBalance(this.state.changeBalance, {type : "rolling", rolling : this.state.changeBalance});

                              }}
                            />
                          </div>

                        </div>
                      </div>

              </span>
          </div> 
          </OverlayPanel>
          
          <div className="row gutter">
            <div className="col-lg-12">
              <div className="panel">
                <div className="panel-heading">
                  <h4>에이전트 비밀번호 변경</h4>
                </div>
                <div className="panel-body">
                  <div className="form-inline">
                    <div className="form-group" style={{ paddingRight: "10px" }}>
                      <span className="md-inputfield">
                        <InputText
                            value={this.state.oldPW}
                            onChange={(e: any) => this.setState({ oldPW: e.target.value })}
                            className="form-control"
                        />
                        <label>기존비밀번호</label>
                        </span>
                    </div>
                    <div className="form-group" style={{ paddingRight: "10px" }}>
                      <span className="md-inputfield">
                        <InputText
                            value={this.state.newPW1}
                            onChange={(e: any) => this.setState({ newPW1: e.target.value })}
                            className="form-control"
                        />
                        <label>신규비밀번호</label>
                        </span>
                    </div>
                    {/* Button Start */}
                      <Button
                          label="확 인"
                          onClick={() => this.handleSave()}
                      />
                    {/* Button End */}
                  </div>
                </div>
              </div>
              
              {
                this.state.admin && (
              <div className="panel">
                <div className="panel-heading">
                  <h4>프로테이지</h4>
                </div>
                <div className="panel-body">
                  {
                          this.state.benefit.map((ss : any) => {
                            return (
                              <div className="p-col-12 ">
                                  <span className="md-inputfield">
                                      <Button label={(ss.benefit_type === 'balance_losing' ? `루징 : ${ss.percent}` : 
                                      (ss.benefit_type === 'agent_slot_rolling' ? `슬롯 롤링 : ${ss.percent}` :
                                      (ss.benefit_type === 'agent_mini_nomal' ? `파워볼 단폴 : ${ss.percent}` :
                                      (ss.benefit_type === 'agent_mini_double' ? `파워볼 조합 : ${ss.percent}` :
                                      (ss.benefit_type === 'agent_slot_rolling' ? `슬롯 롤링 : ${ss.percent}` :  
                                      (ss.benefit_type === 'agent_slot_losing' ? `슬롯 루징 : ${ss.percent}` :  
                                      (ss.benefit_type === 'agent_casino_losing' ? `카지노 카지노 : ${ss.percent}` :  
                                      
                                      `카지노 롤링 : ${ss.percent}` )))))))} />
                                  </span>
                              </div>
                            )
                          })
                        }
                </div>
              </div>
                )
              }
            </div>
          </div>             





      </div>
    );
  }
}

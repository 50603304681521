import React, { Component } from "react";
import { Chart } from "primereact/chart";
import { AgentService } from "../service/agent.service";
import {
  numTomoeny,
} from "../utility/help";

// console.log(process.env.REACT_APP_API_OUTSIDE_BALANCE)

interface State {
  casionRolling: number;
  slotRolling: number;
  totalBalance: number;
  realBetCount: number;
  realMiniCount: number;
  deposit: number;
  withdraw: number;
  userCount: number;
  todayCount: number;
  connectCount: number;
  losing: number;
  rolling: number;

  losingBenefits: number;
  slotBenefits: number;

  regCount: number;
  totalBetCount: number;

  matchBetCount: number;
  matchWaitCount: number;
  miniBetCount: number;
  miniWaitCount: number;

  pieData: any;

  total: any;
  mini: any;
  miniWin: any;

  match: any;
  matchWait: any;

  slot: any;
  casino: any;
  casinoInOut: any;
  management: any;
  outsideBalance: any;
}

export class Dashboard extends Component<any, State> {
  agentService = new AgentService();
  menu: any;
  constructor(props: any) {
    super(props);
    this.state = {
      management: null,
      outsideBalance: 0,
      casionRolling: 0,
      slotRolling: 0,
      totalBalance: 0,
      realBetCount: 0,
      realMiniCount: 0,
      deposit: 0,
      withdraw: 0,
      regCount: 0,
      userCount: 0,
      todayCount: 0,
      connectCount: 0,
      losing: 0,
      rolling: 0,
      losingBenefits: 0,
      slotBenefits: 0,

      

      matchBetCount: 0,
      matchWaitCount: 0,
      miniBetCount: 0,
      miniWaitCount: 0,
      totalBetCount: 0,

      total: {},
      mini: {},
      match: {},
      matchWait: {},
      miniWin: {},
      casinoInOut: {},

      slot: {},
      casino: {},
      pieData: {
        labels: ["A", "B"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      },
    };
    setInterval(
      function (dash) {
        if (window.location.hash.indexOf("dashboard") >= 0) {
          dash.updateDashborad();
        }
      },
      30000,
      this
    );
    this.updateDashborad();

  }

  componentDidMount() {

  }

  updateDashborad = async() => {
    var dash = await this.agentService.get_dashboard()
      if (dash.status == "success") {
    
        this.setState({
          outsideBalance : dash.outsideBalance,
          userCount : dash.userCount,
          deposit: dash.deposit,
          withdraw: dash.withdraw,

          todayCount: dash.todayCount,
          connectCount: dash.connectCount,
          losing: dash.losing,
          rolling: dash.rolling,
          losingBenefits : dash.losingBenefits ,
          slotBenefits: dash.slotBenefits,

        })
    }
  }

  render() {
    const RenderBase = (data :any) => {

      if(data.benefit != null)
      {
        return (
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            <a href="javascript:void(0)" className={"block-60 " + data.className}>
              <h1>{numTomoeny(data.info)} ({data.benefit})</h1>
              <h5>{data.title}</h5>
            </a>
          </div>
        );
      }
      
      return (
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <a href="javascript:void(0)" className={"block-60 " + data.className}>
            <h1>{numTomoeny(data.info)}</h1>
            <h5>{data.title}</h5>
          </a>
        </div>
      );
    };


    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-body">
                {RenderBase({title: "알보유", info: this.state.outsideBalance, className:"green-bg"})}
                {RenderBase({title: "회원수", info: this.state.userCount, className:"yellow-bg" })}
                {RenderBase({title: "현재접속", info: this.state.connectCount, className:"teal-bg"})}
                {RenderBase({title: "롤링", info: this.state.rolling, benefit: this.state.losingBenefits, className:"fb-bg" })}
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

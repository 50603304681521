import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  numTomoeny,
  GetToday,
  convertDate,
  converBalanceStatus,
  convertLogText,
  getDepositColor,
  getWithdrawColor
} from "../../utility/help";

interface BalanceProps {
  logs: any;
}

export class LogBalanceTable extends Component<any, BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
  }

  render() {
    let logs = this.props.logs;
    return (
      <DataTable value={logs} paginatorPosition="both" rows={20} sortOrder={-1}>
        <Column
          style={{ textAlign: "center" }}
          header="아이디"
          body={(rowData: any, column: any) => {
            return rowData.id;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="벨런스 +"
          body={(rowData: any, column: any) => {
            if (rowData.balance >= 0) {
              return (
                <div style={{ color: getDepositColor() }}>
                  {numTomoeny(rowData.balance)}
                </div>
              );
            }

            return "";
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="벨런스 -"
          body={(rowData: any, column: any) => {
            if (rowData.balance <= 0) {
              return (
                <div style={{ color: getWithdrawColor() }}>
                  {numTomoeny(rowData.balance)}
                </div>
              );
            }

            return "";
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="남은 벨런스"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.afterbalance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header={"에이전트"}
          body={(rowData: any, column: any) => {
            return <div>{rowData.agent_tree}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header={"타입"}
          body={(rowData: any, column: any) => {
            return <div>{convertLogText(rowData.logType)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header={"요청시간"}
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.regDate)}</div>;
          }}
          field="depositdate"
        />
        {/* 
        <Column
          style={{ textAlign: "center" }}
          header={"CODE"}
          body={(rowData, column) => {
            return <div>{rowData._id}</div>;
          }}
          field="depositdate"
        /> */}
      </DataTable>
    );
  }
}

import React, { Component } from "react";
import { useCookies } from 'react-cookie';
import { AgentService } from "../service/agent.service";
import { GetTimeStemp } from "../utility/help";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

function RenderPopup(pop: any) {
  pop = pop.pop

  const time = window.localStorage.getItem(`popuptime_${pop.idx}`)
  // const [cookies, setCookie] = useCookies([`popuptime_${pop.idx}`]);
  const [value, setClose] = React.useState(false)

  console.log(GetTimeStemp() - Number(time))
  if(value == true){
    return <></>
  }

  
  if (time != null && GetTimeStemp() - Number(time) < (1000 * 60 * 60 * 8)  ) {
     return (<></>);
  }

  return (
    // <div className="pop01_popup1 draggable02" id="divpopup20210131045005" style={{ position: 'absolute', top: `${pop.posY}px`, left: `${pop.posX}px`, zIndex: 1000 }} >
    //   <div className="pop01_popup_wrap">
    //     <div className="pop01_popup_btn_wrap">
    //       <ul>
    //         <li><a  onClick={() =>  setCookie(`popuptime_${pop.idx}`, GetTimeStemp()) }><span className="pop01_popup_btn">8시간동안 창을 열지 않음</span></a></li>
    //         <li><a 
    //         onClick={() => {
    //           setClose(true)
    //         }}
    //         ><span className="pop01_popup_btn" >닫기 X</span></a></li>
    //       </ul>
    //     </div>
    //     <div className="pop01_popup_box" >
    //       <div className="pop01_popup_text" style={{}}>
    //         <span className="pop01_popup_font1" style={{ borderBottom: '2px solid #fff', marginBottom: '15px', color: 'white',  }}>{pop.title}</span>
    //         <span className="pop01_popup_font2"style={{overflowX: 'hidden'}} >
    //           <div dangerouslySetInnerHTML={{ __html: pop.contents }}></div>
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="pop01_popup1 draggable02" id="divpopup20210131045005" style={{position: 'absolute', top: `${pop.posX}px`, left: isMobile ? `0px` : `${pop.posY}px`, zIndex: 1000}} >
    <div className="pop01_popup_wrap">
        <div className="pop01_popup_btn_wrap">
            <ul>
                <li><a><span className="pop01_popup_btn"  onClick={() => {
                   window.localStorage.setItem(`popuptime_${pop.idx}`, `${GetTimeStemp()}`)
                   setClose(true)
                } }>8시간동안 창을 열지 않음</span></a></li>
                <li><a><span className="pop01_popup_btn" onClick={()=>   setClose(true)}>닫기 X</span></a></li>             
            </ul>
        </div>
        <div className="pop01_popup_box"  style={{padding:'30px', width :pop.width == null ? '500px' : `${pop.width}px`, height:pop.height == null ? '500px' : `${pop.height}px`}}>
        <div className="pop01_popup_text" style={{padding:'30px', width :pop.width == null ? '500px' : `${pop.width}px`, height:pop.height == null ? '500px' : `${pop.height}px`}}>
              <span className="pop01_popup_font1" style={{borderBottom:'2px solid #fff', marginBottom:'15px', color : pop.titleColor}}>{pop.title}</span>
              <span className="pop01_popup_font2" >
                    <div dangerouslySetInnerHTML={{ __html:  pop.contents }}></div>
              </span> 

            </div>

      

        </div>
    </div>
</div>

    )
};


interface State {
  items: any[];
  agents: any[];
  sel_agent: string;
  notice: any;
  activePage: number;
  maxCount: number;
  close_popup: any;
  note_popup: any[];
}

export class NoticeAgent extends Component<any, State> {
  agentService = new AgentService();

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      agents: [{ name: "모두", value: "" }],
      sel_agent: "",
      activePage: 1,
      maxCount: 1,
      notice: {
        del: "n",
        contents: "",
        title: ""
      },

      note_popup: [],
      close_popup: {}     
    }
    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
     this.handleGetNotice();
    // this.handleGetAgent();
  }

  handleGetNotice = () => {
    this.agentService.admin_get_notice_agent().then(data => {
      if (data.status == "success") {
        this.setState({
          items: data.notices
        });
      }
    });
  };

  render() {
    let items = this.state.items;

    console.log(items)

    return <>{items.map((pop, index) => <RenderPopup pop={pop}></RenderPopup>)}</>;

  }
}

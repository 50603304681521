import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { AgentService } from "../service/agent.service";

let REACT_APP_API_CUSTOM = process.env.REACT_APP_API_CUSTOM;


interface Props {
  agentId: string;
}

interface State {
  agent: any;
  userTableValue: any[];
  page: number;
  pages: any;
  field: string;
  benefits: any[];
}

export class AgentDetail extends Component<Props, State> {
  agentService: AgentService;
  constructor(props: Props) {
    super(props);
    this.state = {
      agent: {},
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      benefits: []
    };

    this.agentService = new AgentService();

    // this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);

    this.agentServicehandleGetAgentBen()
  }

  componentDidMount() {
 
  }

  agentServicehandleGetAgentBen = async()=>{
    const data = await this.agentService.admin_get_agent_benefit(this.props.agentId).then(s => s)
    if(data.status == "success") {
      this.setState({benefits : data.benefits })
    }
  }

  handleUpdateBenefit = async(benefit_type : string) =>{

    const benefit= Object.values(this.state.benefits).find(s => s.benefit_type == benefit_type)
    if(benefit == null){
      return 
    }
    
    const data = await this.agentService.admin_update_agent_benefit(benefit).then(s => s)
    if(data.status == "success") {
      confirmAlert({
        title: "베네핏 ",
        message: "수정에 성공하였습니다",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              }
          }
          
          ]})
    }
    else {
      confirmAlert({
        title: "베네핏 ",
        message: "",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              }
          }
          
          ]})
    }

  }

  handleUserChange = (e: any) => {
    if (this.state.agent[e.target.id] != undefined) {
      this.state.agent[e.target.id] = e.target.value;

      this.setState({ agent: this.state.agent });
    }
  };

  render() {

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="card card-w-title">
                <div className="card-title">에이전트 기본정보</div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">아이디</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px"
                      }}
                    >
                      {this.props.agentId}
                    </p>
                  </div>


                </div>
              </div>
            </div>

            <div className="p-col-9">
            
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { AgentService } from "../service/agent.service";
import { SelectButton } from "primereact/selectbutton";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  numTomoeny,
  pad2,
  ConvertCalendar,
  GetYesterDay,
} from "../utility/help";

interface BalanceState {
  details: any;
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;

  options: any[];
  options_type: string;
  begin: any;
  end: any;
  agents: any;
  agent_name: any;
  management: any;
  agents_option: any;
  totalBalance: any;
}

export class CalculateAgent extends Component<any, BalanceState> {
  agentService: AgentService;
  constructor(props: any) {
    super(props);

    let today = GetYesterDay();

    console.log(today);

    this.state = {
      begin: today.begin,
      end: today.end,
      findText: "",
      searchText: "",
      selectItem: {},
      management: {},
      totalBalance: {},

      details: [],
      agents: [],
      agents_option: [{ name: "에이전트 선택", valuse: "" }],

      options: [{ label: "입출금/통계", value: "balance" }],

      options_type: "balance",

      select: [{ label: "선택", value: null }],

      agent_name: "",
    };

    this.agentService = new AgentService();
  }

  handleCalculate(agent_name: String, begin: Date, end: Date) {
    this.agentService
      .agent_get_calculate(agent_name, begin, end)
      .then((data) => {
        if (data.status == "success") {
          let detail = Object.values(data.calculate);
          detail.sort(function(a: any, b: any) {
            return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
          });

          let totalBalance = {};
          for (let balances of Object.values(data.calculate) as any) {
            for (let key of Object.keys(balances as [])) {
              if (typeof balances[key] === "number") {
                if (totalBalance[key] == null) {
                  totalBalance[key] = 0;
                }
                totalBalance[key] += Number(balances[key] as Number);
              } else if (typeof balances[key] === "object") {
                for (let key2 of Object.keys(balances[key] as [])) {
                  if (typeof balances[key][key2] === "number") {
                    if (totalBalance[`${key}.${key2}`] == null) {
                      totalBalance[`${key}.${key2}`] = 0;
                    }

                    totalBalance[`${key}.${key2}`] += Number(
                      balances[key][key2] as Number
                    );
                  }
                }
              }
            }
          }

          this.setState({ details: detail, totalBalance });
        }
      });
  }

  handleAgentList() {
    this.agentService.agent_get_list().then((data) => {
      if (data.status == "success") {
        let option: any = [];
        for (let agent of data.agents) {
          option.push({
            label: agent.nick,
            value: agent.agent,
            key: agent.agent,
          });
        }
        this.setState({ agents_option: option });
      }
    });
  }
  componentDidMount() {
    this.handleAgentList();

    this.handleCalculate("", this.state.begin, this.state.end);
  }

  render() {
    const total =
      this.state.totalBalance["balance.depositBalance"] +
      this.state.totalBalance["agentGive.depositBalance"] +
      this.state.totalBalance["agentChangeBalance"] -
      (this.state.totalBalance["balance.withdrawBalance"] +
        this.state.totalBalance["agentExchangeBalance"] +
        this.state.totalBalance["agentGive.withdrawBalance"]);

    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header="에이전트"
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>충전</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>환전</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>파트너 지급</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>파트너 차감</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={
              <div>
                <div>금일 보유금</div>
                <div>전일 보유금</div>
              </div>
            }
          />

          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>배팅</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>파트너 충전</div>}
          />
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>파트너 환전</div>}
          />
          {/* <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>파트너 잔액</div>}
          /> */}
          <Column
            style={{
              textAlign: "center",
              borderRight: "1px solid #d8d8d8",
              background: "#27765c",
              color: "#fff",
            }}
            header={<div>매출액</div>}
          />
        </Row>
        {
          <Row>
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header="정산 합계"
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(
                    this.state.totalBalance["balance.depositBalance"]
                  )}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(
                    this.state.totalBalance["balance.withdrawBalance"]
                  )}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(
                    this.state.totalBalance["agentGive.depositBalance"]
                  )}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(
                    this.state.totalBalance["agentGive.withdrawBalance"]
                  )}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={<div> </div>}
            />

            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(this.state.totalBalance["betting.betDoBalance"])}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(this.state.totalBalance["agentChangeBalance"])}
                </div>
              }
            />
            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={
                <div>
                  {numTomoeny(this.state.totalBalance["agentExchangeBalance"])}
                </div>
              }
            />
            {/* <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={<div>{}</div>}
            /> */}

            <Column
              style={{
                textAlign: "center",
                borderRight: "1px solid #d8d8d8",
                backgroundColor: "#8bc34a57",
              }}
              header={<div>{numTomoeny(total)}</div>}
            />
          </Row>
        }
      </ColumnGroup>
    );

    const RenderBalance = () => {
      if (this.state.options_type == "balance") {
        return (
          <DataTable
            value={this.state.details}
            paginatorPosition="both"
            rows={9999}
            sortOrder={-1}
            scrollable={true}
            headerColumnGroup={headerGroup}
          >
            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                let date = new Date(rowData.date);

                return (
                  pad2(date.getMonth() + 1) +
                  "-" +
                  pad2(date.getDate()) +
                  " " +
                  pad2(date.getHours())
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.balance == null) {return 0}
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.balance.depositBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.balance == null) {return 0}

                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.balance.withdrawBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.agentGive == null) {return 0}

                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentGive.depositBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.agentGive == null) {return 0}
                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.agentGive.withdrawBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <div style={{ color: "green" }}>
                      {numTomoeny(rowData.inBalance)}
                    </div>
                    <div style={{ color: "green" }}>
                      {numTomoeny(rowData.ydInBalance)}
                    </div>
                  </div>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.betting == null) {return 0}
                return (
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(rowData.betting.betDoBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentChangeBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.agentExchangeBalance)}
                  </span>
                );
              }}
            />

{/* 
<Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                const money = numTomoeny(rowData.agentBalance)
                console.log(money)
                return <div style={{ color: "green" }}>{`${money}`}</div>
              }}
            /> */}

     
            <Column
              style={{
                textAlign: "center",
                width: "80px",
                borderRight: "1px solid #d8d8d8",
              }}
              body={(rowData: any, column: any) => {
                if(rowData.balance == null || rowData.agentGive == null) {return 0}
                const total =
                  rowData.balance.depositBalance +
                  rowData.agentGive.depositBalance +
                  rowData.agentChangeBalance -
                  (rowData.balance.withdrawBalance +
                    rowData.agentGive.withdrawBalance +
                    rowData.agentExchangeBalance);
                return (
                  <span style={{ color: "green" }}>{numTomoeny(total)}</span>
                );
              }}
            /> 
          </DataTable>
        );
      }
    };

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>매출 관리</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline">
                    <div
                      className="form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.begin)}
                        showWeek={false}
                        hourFormat="24"
                        onChange={(e: any) => {
                          this.setState({ begin: e.value });
                        }}
                        locale={ConvertCalendar()}
                      />
                      &nbsp;~&nbsp;
                      <Calendar
                        placeholder="End"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.end)}
                        showWeek={false}
                        hourFormat="24"
                        onChange={(e: any) => {
                          this.setState({ end: e.value });
                        }}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    {/* Button Start */}
                    <Button
                      label="검색"
                      onClick={() => {
                        this.handleCalculate(
                          this.state.agent_name,
                          this.state.begin,
                          this.state.end
                        );
                      }}
                    />
                    {/* Button End */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <SelectButton
                    value={this.state.options_type}
                    options={this.state.options}
                    onChange={(e) => {
                      if (e.value == null) {
                        return;
                      }
                      this.setState({ options_type: e.value });
                    }}
                  />
                </div>
              </div>
              <div className="panel-body">{RenderBalance()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
} from "../../utility/help";

interface SlotProps {
  mode: any;
  bets: any;
  // OnEditItem: any;
  // OnSelectItem: any;
  // const mode = this.props.mode;
  // const bets = this.props.bets;
  // const OnEditItem = this.props.OnEditItem;
  // const OnSelectItem = this.props.OnSelectItem;
}

export class BetFxTable extends Component<SlotProps> {
  constructor(props: SlotProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bets;
    // const OnEditItem = this.props.OnEditItem;
    // const OnSelectItem = this.props.OnSelectItem;

    return (
      <DataTable
        value={bets}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="아이디"
          field="id"
          // body={(rowData: any, column: any) => {
          //   return <div style={{ color: rowData.color }}>{rowData.id}</div>;
          // }}
          body={(rowData: any, column: any) => {
            return <div onClick={() => {}}>{rowData.id}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="win"
          header="시가"
          body={(rowData: any, column: any) => {
            return <div>{rowData.open}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="win"
          header="종가"
          body={(rowData: any, column: any) => {
            return <div>{rowData.close}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="win"
          header="배팅금"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.betBalance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="이긴금액"
          body={(rowData: any, column: any) => {
            return <div>{rowData.winBalance}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="커런트"
          body={(rowData: any, column: any) => {
            return <div>{rowData.currency}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="bet"
          header="시작시간"
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.beginDate)}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="bet"
          header="종료시간"
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.endDate)}</div>;
          }}
        />
      </DataTable>
    );
  };
}

import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";

interface Props {
  SearchData: (field: any, sort: any) => any;
  GetConnectUserList: () => any;
}

interface State {
  selectBox: any[];
  dropdownItem: any;
  seach: any;
  userType: any;

  userValue: any;
}

export class HelpBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      seach: "",
      dropdownItem: null,
      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "userId" },
        { label: "닉네임", value: "nick" },
        { label: "이름", value: "bankOwner" },
        { label: "추천인", value: "friendId" },
        { label: "전화번호", value: "mobile" },
        { label: "에이전트", value: "agent" },
        { label: "IP 검색", value: "loginip" },
      ],
      userType: { name: "모든 회원", value: "all" },
      userValue: [
        { name: "모든 회원", value: "all" },
        { name: "현재 접속중 회원", value: "connect" },
      ],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="row gutter">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="form-inline">
              <div className="form-group" style={{ paddingRight: "10px" }}>
                <Dropdown
                  options={this.state.selectBox}
                  value={this.state.dropdownItem}
                  onChange={(event) =>
                    this.setState({ dropdownItem: event.value })
                  }
                  autoWidth={false}
                  style={{ width: "120px" }}
                  className="form-control"
                />
              </div>
              <div className="form-group" style={{ paddingRight: "10px" }}>
                <span className="md-inputfield">
                  <InputText
                    style={{ width: "200px" }}
                    value={this.state.seach}
                    onChange={(e: any) =>
                      this.setState({ seach: e.target.value })
                    }
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        this.props.SearchData(
                          this.state.dropdownItem,
                          this.state.seach
                        );
                      } else {
                      }
                    }}
                    className="form-control"
                  />
                  <label>검색</label>
                </span>
              </div>
              {/* Button Start */}
              <Button
                label="검색"
                onClick={() => {
                  this.props.SearchData(
                    this.state.dropdownItem,
                    this.state.seach
                  );
                }}
              />
              {/* Button End */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <SelectButton
              style={{ paddingTop: 10 }}
              optionLabel="name"
              value={this.state.userType}
              options={this.state.userValue}
              onChange={(e: any) => {
                if (e.target.value != null) {
                  this.setState({ userType: e.target.value });

                  if (e.target.value === "all") {
                    this.props.SearchData(
                      this.state.dropdownItem,
                      this.state.seach
                    );
                  } else if (e.target.value === "connect") {
                    this.props.GetConnectUserList();
                  }
                }
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

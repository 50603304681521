import React, { Component } from "react";
import { UserService } from "../service/user.service";
import { UserTable } from "../share/user/user-table";
import { SubPaging } from "../share/sub-paging";


interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
  level: any;
}

export class UserConeect extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regDate",
      sort: 1,
      maxCount: 1,
      level : 4,
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_health_list(page, find, search, sortType, sort, "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
          level: data.level,
        
        });
      });
  };

  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    console.log(search, find);
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  MoveSel = (page: any) => {
    this.setState({ page: page });
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-body">
                  <SubPaging
                    activePage={this.state.page}
                    maxCount={this.state.maxCount}
                    limit={20}
                    MoveSel={(page: number) => {
                      this.MoveSel(page);
                    }}
                  ></SubPaging>

                  <UserTable
                    level={this.state.level}
                    userTableValue={this.state.userTableValue}
                    mode="used"
                    handleUseStauseChange={() => {}}
                  ></UserTable>

                  <SubPaging
                    activePage={this.state.page}
                    maxCount={this.state.maxCount}
                    limit={20}
                    MoveSel={(page: number) => {
                      this.MoveSel(page);
                    }}
                  ></SubPaging>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

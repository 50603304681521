import React, { Component } from "react";
import classNames from "classnames";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppFooter } from "./AppFooter";
import { Route } from "react-router-dom";
import cookie from "react-cookies";
import { OverlayPanel } from "primereact/overlaypanel";
import { confirmAlert } from "react-confirm-alert";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { UserList } from "./main/userlist";
import { UserConeect } from "./main/userconeect";
import { UserDetail } from "./main/userdetail";
import { Dashboard } from "./main/dashboard";
import { AgentPW } from "./main/agentpw";
import { DeporsitAndWithdraw } from "./main/deporsitandwithdraw";
import { BetLive } from "./main/betlive";
import { BetCasino } from "./main/betcasino";
import { BetSlot } from "./main/betslot";
import { BetMini } from "./main/betmini";
import { BetMatch } from "./main/betmatch";
import { BetFX } from "./main/betfx";
import { Balance } from "./main/balance";
import { BalanceGiveUser } from "./main/balancegiveuser";
import { BalanceLog } from "./main/balancelog";
import { Agent } from "./main/agent";
import { AgentBalanceList } from "./main/agentbalancelist";
import { AgentBalanceLog } from "./main/agentbalancelog";
import { AgentDetail } from "./main/agentdetail";
import { CalculateAgent } from "./main/calculateagent";
import { CalculateSubAgent } from "./main/calculatesubagent";
import { CalculateSubCasino } from "./main/calculateagentcasino";
import { CalculateSubCasinoNSlot } from "./main/calculateagentcasinonslot";
import { CalculateAgentCSWinLose } from "./main/calculateagentcswinlose";
import { CalculateSubAgentKcrown } from "./main/calculatesubagentkcrown";
import { CalculateAgentKom } from "./main/calculateagentkom";
import { CalculateAgentKomPW } from "./main/calculateagentkompw";
import { NoticeAgent } from "./main/noticeagent";
import { AgentHelp } from "./main/agenthelp";
import { AgentNote } from "./main/agentnote";
import { withRouter } from "react-router";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "./ripple.js";
import "./App.css";
import { AgentService } from "./service/agent.service";
import { AxiosMag } from "./service/axios.service";
import SoundPlays from "./soundplay";
import GitInfo from "react-git-info/macro";
import { numTomoeny } from "./utility/help";
import { NONAME } from "dns";
interface Props {}
interface State {
  horizontal: boolean;
  topbarSize: string;
  topbarColor: string;
  menuColor: string;
  layoutColor: string;
  themeColor: string;
  menuActive: boolean;
  configDialogActive: boolean;
  topbarUserMenuActive: boolean;
  admin: any;
  management: any;
  benefit: any;

  changBalance: number;
  changeBalance: number;
  noteCount: number;
  helpCount: number;
  code : number;
}

console.log(process.env.REACT_APP_API_CALCULATE_CASINO_CAL);

class App extends Component<Props, State> {
  Interval: any = null;
  layoutContainer: any;
  agentService: any = new AgentService();

  chageBalance: any = React.createRef();
  exchageBalance: any = React.createRef();
  changeBalance: any = React.createRef();

  constructor(props: any) {
    super(props);

    this.state = {
      admin: {},
      management: {},
      horizontal: true,
      topbarSize: "large",
      topbarColor: "layout-topbar-blue",
      menuColor: "layout-menu-light",
      layoutColor: "blue",
      themeColor: "blue",
      menuActive: false,
      configDialogActive: false,
      topbarUserMenuActive: false,
      changBalance: 0,
      changeBalance: 0,
      noteCount: 0,
      helpCount: 0,
      benefit: [],
      code : (new Date()).getTime()
    };

    this.onTopbarUserMenuButtonClick = this.onTopbarUserMenuButtonClick.bind(
      this
    );
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    this.changeTopbarSize = this.changeTopbarSize.bind(this);
    this.changeMenuToHorizontal = this.changeMenuToHorizontal.bind(this);
    this.changeMenuTheme = this.changeMenuTheme.bind(this);
    this.changeComponentTheme = this.changeComponentTheme.bind(this);
    this.changePrimaryColor = this.changePrimaryColor.bind(this);
    this.onToggleBlockBodyScroll = this.onToggleBlockBodyScroll.bind(this);
    this.isHorizontalMenuActive = this.isHorizontalMenuActive.bind(this);

  }

  update = () => {
    try {
      if (location.hash.indexOf("agentDetail") >= 0) {
        return;
      }

      let session = cookie.load("session");
      AxiosMag.SetSession(session);

      this.agentService.agent_check_health(session).then((data: any) => {
        if (data.status == "success") {
          this.setState({
            admin: data.admin,
            noteCount: data.noteCount,
            helpCount: data.helpCount,
            benefit: data.benefit,
          });

          if (data.noteCount >= 1) {
             SoundPlays("agentHelpCount");
          }

          if (data.helpCount >= 1) {
             SoundPlays("agentHelpCount");
          }
        } else {
          cookie.remove("admin", { path: "/" });

          window.location.hash = "/";
          this.setState({
            admin: null,
          });
        }
      });
    } catch {}
  };

  menuClick = false;
  configMenuClick = false;
  userMenuClick = false;
  menu: any[] = [];

  onToggleBlockBodyScroll(add: boolean) {
    if (add) this.blockBodyScroll();
    else this.unblockBodyScroll();

    this.configMenuClick = true;
  }

  blockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.add(className);
    } else {
      document.body.className += ` ${className}`;
    }
  }

  unblockBodyScroll() {
    let className = `blocked-scroll${
      this.state.horizontal ? "-horizontal-menu" : ""
    }`;
    if (document.body.classList) {
      document.body.classList.remove(className);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + `${className}`.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  onTopbarUserMenuButtonClick(event: any) {
    this.userMenuClick = true;
    this.setState({ topbarUserMenuActive: !this.state.topbarUserMenuActive });

    event.preventDefault();
  }

  onRootMenuItemClick(event: any) {
    this.menuClick = true;

    if (this.isHorizontalMenuActive()) {
      this.setState({
        menuActive: !this.state.menuActive,
      });
    }
  }

  onMenuItemClick(event: any) {
    if (!event.item.items) {
      this.setState({ menuActive: false });
    }
  }

  onSidebarClick = (event: any) => {
    this.menuClick = true;
  };

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isHorizontalMenuActive() {
    return this.state.horizontal && !this.isMobile();
  }

  changeTopbarSize(event: any) {
    this.setState({ topbarSize: event.size });

    event.originalEvent.preventDefault();
  }

  changeTopbarTheme(event: any) {
    this.setState({ topbarColor: "layout-topbar-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeMenuToHorizontal(event: any) {
    this.setState({ horizontal: event.mode });

    event.originalEvent.preventDefault();
  }

  changeMenuTheme(event: any) {
    this.setState({ menuColor: "layout-menu-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeComponentTheme(event: any) {
    this.setState({ themeColor: event.theme });

    let element = document.getElementById("theme-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + event.theme + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  changePrimaryColor(event: any) {
    this.setState({ layoutColor: event.color });

    let element = document.getElementById("layout-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "layout-" + event.color + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  componentDidMount = () => {
    // const [cookies, setCookie] = useCookies(["session"]);
    // session: string;
    let session = cookie.load("session");
    AxiosMag.SetSession(session);

    this.agentService
      .admin_get_management()
      .then((s) => this.setState({ management: s.management }));



      if(this.Interval === null) {
        this.Interval = setInterval(() => {
          this.update();
        }, 30000);
        this.update();
      }
  
  };

  createMenu() {
    this.menu = [
      { label: "Dashboard", icon: "dashboard", to: "/dashboard" },
      { label: "유저 관리", icon: "list", to: "userlist" },
      { label: "현재 접속자", icon: "list", to: "userconeect" },
      { label: "충/환전내역", icon: "list", to: "deporsitAndwithdraw" },

      { label: "에이전트 충/환전/알", icon: "list", to: "agentBalanceList" },
      { label: "유저 알지급/회수", icon: "list", to: "balancegiveuser" },

      {
        label: "배팅/롤링 내역",
        icon: "list",
        to: "betslot",
      },
      // {
      //   label: "일/월별 통계",
      //   icon: "list",
      //   to: "calculateagent",
      // },

      {
        label: "일/월별 통계",
        icon: "list",
        to: "calculatesubagent",
      },

      {
        label: "알신청/수수료전환",
        icon: "list",
        to: "balance",
      },

      {
        label: "파트너 관리",
        icon: "list",
        to: "agent",
      },
      {
        label: "문의",
        icon: "list",
        to: "agenthelp",
      },
    ];
  }

  handleWirteHelp = async () => {
    let data = await this.agentService
      .admin_wirte_agent_help({ title: "-자동 계좌문의", contents: "-문의" })
      .then((data) => data);
    if (data.status == "success") {
      confirmAlert({
        title: "계좌문의",
        message: "계좌문의에 성공하였습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "계좌문의",
        message: data.ms == null ? "계좌문의 작성 실패.": data.ms,
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    }
  };

  handleExchangeBalance = (balance, ex) => {
    if (Number(balance) % 1000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (ex.type == "rolling") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이젼트 포인트 캐쉬전환 하시겠습니까?.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_rolling(ex).then((s) => {
                if (s.status == "success") {
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: "변환에 성공하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else{
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: s.ms,
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
            },
          },
          {
            label: "취소",
            onClick: () => {},
          },
        ],
      });
    } else if (ex.type == "balance") {
      if (Number(balance) % 10000 > 0) {
        confirmAlert({
          title: "에이전트 벨런스",
          message: "환전 금액을 10,000 단위 로 입력해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }

      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              ex.code = this.state.code
              this.agentService.agent_exchange_balance(ex).then((s) => {
                if (s.status == "success") {
                  window.location.reload();
                } else if (s.status == "balance") {
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: "에이젼트 보유금액이 환전신청금액보다 작습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                } else if (s.status == "wait") {
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: "대기중인 충전 / 환전이 있습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
            },
          },
        ],
      });
    }
  };

  

  handleWithdrawBalance = (balance, ex) => {
    if (Number(balance) % 1000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(balance) % 10000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "환전 금액을 10,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "에이전트 벨런스",
      message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            ex.code = this.state.code
            this.agentService.agent_withdraw_balance(ex).then((s) => {
              if (s.status == "success") {
                window.location.reload();
              } else if (s.status == "balance") {
                confirmAlert({
                  title: "에이전트 벨런스",
                  message: "에이젼트 보유금액이 환전신청금액보다 작습니다.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            });
          },
        },
      ],
    });
  };


  handleChangeBalance = (balance: any, ex: any) => {
    if (Number(balance) % 10000 > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "충전할 금액은  10,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "에이전트 벨런스",
      message: "에이전트 충전신청합니다.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            ex.code = this.state.code
            this.agentService.agent_change_balance(ex).then((s) => {
              if (s.status == "success") {
                window.location.reload();
              }
              else {
                confirmAlert({
                  title: "에이전트 벨런스",
                  message: "대기중인 충전 내역이 있습니다.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });      
              }
            });
          },
        },
      ],
    });
  };

  render() {
    this.createMenu();

    if (this.state.admin == null) {
      window.location.href = "/";
    }

    const layoutContainerClassName = classNames(
      "layout-wrapper",
      "layout-menu-light",
      "layout-topbar-blue",
      "layout-menu-static",
      this.state.topbarUserMenuActive
        ? "layout-menu-mobile-active"
        : "layout-menu-active",
      "p-input-filled",

      this.state.topbarColor,
      this.state.menuColor
    );

    const onCloseMenu = () => {
      if (this.isMobile()) {
        this.setState({ topbarUserMenuActive: false });
      }
    };

    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

    let hash = window.location.hash;

    const gitInfo = GitInfo();

    // const PostPage = (match: any) => {
    //   return <div>blablabla</div>;
    // };
    if (hash.indexOf("userDetail") >= 0) {
      if (process.env.REACT_APP_DELETE_GIVE_BALANCE === "y") {
        return <></>;
      }

      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/userDetail/:agentID"
              component={(match: any) => {
                return (
                  <UserDetail userID={match.match.params.agentID}></UserDetail>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.indexOf("agentDetail") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/agentDetail/:agentID"
              component={(match: any) => {
                return (
                  <AgentDetail
                    agentId={match.match.params.agentID}
                  ></AgentDetail>
                );
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        {/* Topbar */}
        <header className="clearfix">
          <div className="logo"></div>
          <div className="pull-right">
            <ul id="header-actions" className="clearfix">
              <li
                className="list-box"
                style={{ paddingTop: "10px", paddingLeft: "30px" }}
              >
                <span style={{ fontSize: "15px", color: "#fff" }}>보유금</span>
                <span
                  className="badge-bdr badge badge-default"
                  style={{
                    background: "#fff",
                    height: "30px",
                    paddingTop: "7px",
                  }}
                >
                  {numTomoeny(this.state.admin.balance)}
                </span>

                <button
                  className="btn btn-warning"
                  type="button"
                  style={{ marginLeft: "5px" }}
                  onClick={(e: any) => {
                    this.chageBalance.current.toggle(e);
                  }}
                >
                  충전요청
                </button>
                <button
                  className="btn btn-success"
                  type="button"
                  style={{ marginLeft: "5px" }}
                  onClick={(e: any) => {
                    this.exchageBalance.current.toggle(e);
                  }}
                >
                  환전요청
                </button>
                <button
                  className="btn btn-info"
                  type="button"
                  style={{ marginLeft: "5px", backgroundColor: "#26463a" }}
                  onClick={(e: any) => {
                    this.handleWirteHelp();
                  }}
                >
                  계좌문의
                </button>
              </li>
              <li
                className="list-box"
                style={{ paddingTop: "10px", paddingLeft: "30px" }}
              >
                <span style={{ fontSize: "15px", color: "#fff" }}>
                  보유포인트
                </span>
                <span
                  className="badge-bdr badge badge-default"
                  style={{
                    background: "#fff",
                    height: "30px",
                    paddingTop: "7px",
                  }}
                >
                  {numTomoeny(this.state.admin.rolling)}
                </span>

                <button
                  className="btn btn-warning"
                  type="button"
                  style={{ marginLeft: "5px" }}
                  onClick={(e: any) => {
                    this.changeBalance.current.toggle(e);
                  }}
                >
                  포인트 캐시전환
                </button>
              </li>
              <li
                className="list-box"
                style={{ paddingTop: "10px", paddingLeft: "30px" }}
              >
                &nbsp;
              </li>
              <li className="list-box user-admin dropdown">
                <div className="admin-details">
                  <div className="name">{this.state.admin.agent}</div>
                  <div className="designation"></div>
                </div>
                <a
                  id="drop4"
                  href="#"
                  role="button"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon-account_circle"></i>
                </a>
                <ul className="dropdown-menu">
                  <li className="dropdown-content">
                    <a >&nbsp;</a>
                    <a
                      onClick={() => {
                        window.location.hash = "agentpw";
                      }}
                    >
                      개인정보변경
                    </a>
                    <a
                      onClick={() => {
                        cookie.remove("session", { path: "/" });
                        window.location.hash = "/";
                      }}
                    >
                      로그아웃
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </header>
        {/* Topbar */}

        <div className="container-fluid">
          <div className="left-sidebar">
            <div
              className="panel-group"
              id="leftSidebar"
              role="tablist"
              aria-multiselectable="true"
            >
              <div className="panel">
              <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      onClick={()=>window.location.hash = '#dashboard'}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      대쉬보드
                    </a>
                  </h4>
                </div>
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      회원관리
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="online-users">
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/userlist"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>회원관리</span>
                          <div className="online"></div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/balancegiveuser"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>회원/파트너 캐시내역</span>
                          <div className="online"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      href="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      파트너관리
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="online-users">
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/agent"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>파트너관리</span>
                          <div className="online"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      href="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      결재관리
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="online-users">
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/deporsitAndwithdraw"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>충전/환전 관리</span>
                          <div className="online"></div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/agentBalanceList"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>파트너 충전/환전</span>
                          <div className="online"></div>
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            this.state.noteCount === 0
                              ? "#/calculateagent"
                              : "#/agentnote"
                          }
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>매출관리</span>
                          <div className="online"></div>
                        </a>
                      </li>

                      <li>
                          <a
                            data-status="online"
                            data-original-title=""
                            href={
                              this.state.noteCount === 0
                                ? "#/calculatesubagent"
                                : "#/agentnote"
                            }
                            onClick={() => onCloseMenu()}
                          >
                            <span>매출관리</span>
                            <div className="online"></div>
                          </a>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      href="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      게임관리
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="online-users">
              
                        <li>
                          <a
                            data-status="online"
                            data-original-title=""
                            href={
                              this.state.noteCount === 0
                                ? "#/betslot"
                                : "#/agentnote"
                            }
                            onClick={() => onCloseMenu()}
                          >
                            <span>슬롯 베팅리스트</span>
                            <div className="online"></div>
                          </a>
                        </li>
                        <li>
                          <a
                            data-status="online"
                            data-original-title=""
                            href={
                              this.state.noteCount === 0
                                ? "#/betcasino"
                                : "#/agentnote"
                            }
                            onClick={() => onCloseMenu()}
                          >
                            <span>카지노 베팅리스트</span>
                            <div className="online"></div>
                          </a>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#leftSidebar"
                      href="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      쪽지함
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div className="panel-body">
                    <ul className="online-users">
                      <li>
                        <a
                          href="#/agenthelp"
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span
                            className={
                              this.state.helpCount > 0
                                ? "layout-menuitem-text element"
                                : "layout-menuitem-text"
                            }
                          >
                            고객센터
                          </span>
                          <div className="online"></div>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#/agentnote"
                          onClick={() => onCloseMenu()}
                          data-status="online"
                          data-original-title=""
                        >
                          <span>쪽지함</span>
                          <span
                            className="p-ink"
                            style={{
                              color: this.state.noteCount > 0 ? "red" : "white",
                            }}
                          >
                            ({this.state.noteCount})
                          </span>
                          <div className="online"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-wrapper">
            <div className="main-container">
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/userlist" component={UserList} />
              <Route path="/userconeect" component={UserConeect} />

              <Route
                path="/deporsitAndwithdraw"
                component={DeporsitAndWithdraw}
              />

              <Route path="/betmatch" component={BetMatch} />
              <Route path="/betlive" component={BetLive} />
              <Route path="/betcasino" component={BetCasino} />
              <Route path="/betslot" component={BetSlot} />
              <Route path="/betmini" component={BetMini} />
              <Route path="/betfx" component={BetFX} />

              <Route path="/balance" component={Balance} />
              <Route path="/balanceLog" component={BalanceLog} />
              <Route path="/agent" component={Agent} />
              <Route path="/agentBalanceList" component={AgentBalanceList} />
              <Route path="/agentbalancelog" component={AgentBalanceLog} />
              <Route path="/agenthelp" component={AgentHelp} />
              <Route path="/calculatesubagent" component={CalculateSubAgent} />
              {/* <Route
                path="/calculatesubcasino"
                component={CalculateSubCasino}
              />
              <Route
                path="/calculatesubcasinonslot"
                component={CalculateSubCasinoNSlot}
              />
              <Route
                path="/calculateagentcswinlose"
                component={CalculateAgentCSWinLose}
              />
              <Route
                path="/calculatesubagentkcrown"
                component={CalculateSubAgentKcrown}
              />
              <Route
                path="/calculatesubagentkom"
                component={CalculateSubAgentKcrown}
              />
              <Route path="/calculateagentkom" component={CalculateAgentKom} />
              <Route
                path="/calculateagentkompw"
                component={CalculateAgentKomPW}
              /> */}

              <Route path="/agentnote" component={AgentNote} />
              <Route path="/agentpw" component={AgentPW} />

              {process.env.REACT_APP_DELETE_GIVE_BALANCE === "y" ? (
                <> </>
              ) : (
                <Route path="/balancegiveuser" component={BalanceGiveUser} />
              )}

              <Route path="/calculateagent" component={CalculateAgent} />
            </div>

            <footer className="footer">
              <div className="row gutter">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  V3 {gitInfo.commit.date} {gitInfo.commit.hash}{" "}
                  {gitInfo.branch}
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                  style={{ textAlign: "right" }}
                >
                  <span className="pi-md-copyright"></span>
                  <span>All Rights Reserved</span>
                </div>
              </div>
            </footer>

            <div id="agentHelpCount" style={{ display: "none" }}>
              0
            </div>
          </div>

          <NoticeAgent></NoticeAgent>

        </div>

        <OverlayPanel
          ref={this.chageBalance}
          id="overlay_panel"
          style={{ width: "500px" }}
          showCloseIcon={false}
        >
          <div className="md-inputfield">
            <span className="p-float-label" style={{ padding: "20px" }}>
              <div className="card-title">충전신청</div>

              <div className="p-md-12">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-1">
                    <label htmlFor="input"></label>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <InputText
                      id="input"
                      value={this.state.changeBalance}
                      onChange={(even: any) => {
                        this.setState({ changeBalance: even.target.value });
                      }}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-5"
                    style={{ paddingLeft: "40px" }}
                  >
                    <Button
                      label="확인"
                      className="p-button-warning"
                      style={{ color: "black" }}
                      onClick={(even: any) => {
                        this.handleChangeBalance(this.state.changeBalance, {
                          chBalance: this.state.changeBalance,
                          type: "balance",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </span>
          </div>
        </OverlayPanel>

        <OverlayPanel
          ref={this.exchageBalance}
          id="overlay_panel"
          style={{ width: "500px" }}
          showCloseIcon={false}
        >
          <div className="md-inputfield">
            <span className="p-float-label" style={{ padding: "20px" }}>
              <div className="card-title">환전신청</div>

              <div className="p-md-12">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-1">
                    <label htmlFor="input"></label>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <InputText
                      id="input"
                      value={this.state.changeBalance}
                      onChange={(even: any) => {
                        this.setState({ changeBalance: even.target.value });
                      }}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-5"
                    style={{ paddingLeft: "40px" }}
                  >
                    <Button
                      label="확인"
                      className="p-button-warning"
                      style={{ color: "black" }}
                      onClick={(even: any) => {
                        this.handleWithdrawBalance(this.state.changeBalance, {
                          balance: this.state.changeBalance,
                          type: "balance",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </span>
          </div>
        </OverlayPanel>

        <OverlayPanel
          ref={this.changeBalance}
          id="overlay_panel"
          style={{ width: "500px" }}
          showCloseIcon={false}
        >
          <div className="md-inputfield">
            <span className="p-float-label" style={{ padding: "20px" }}>
              <div className="card-title">포인트 캐시전환</div>

              <div className="p-md-12">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-1">
                    <label htmlFor="input"></label>
                  </div>
                  <div className="p-col-12 p-md-4">
                    <InputText
                      id="input"
                      value={this.state.changeBalance}
                      onChange={(even: any) => {
                        this.setState({ changeBalance: even.target.value });
                      }}
                    />
                  </div>
                  <div
                    className="p-col-12 p-md-5"
                    style={{ paddingLeft: "40px" }}
                  >
                    <Button
                      label="확인"
                      className="p-button-warning"
                      style={{ color: "black" }}
                      onClick={(even: any) => {
                        this.handleExchangeBalance(this.state.changeBalance, {
                          type: "rolling",
                          rolling: this.state.changeBalance,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </span>
          </div>
        </OverlayPanel>
      </>
    );
  }
}

export default App;

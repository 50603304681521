import React, { Component } from "react";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";
import { CustomPagination } from "../share/custom-pagination";
import { LogAgentBalanceTable } from "../share/log/log-agent-balance-table";
import { SelectButton } from "primereact/selectbutton";
import { numTomoeny, ConvertCalendar, GetToday } from "../utility/help";

interface BalanceState {
  balances: any;
  agentBalance: any;
  findText: any;
  searchText: any;
  maxCount: any;
  activePage: number;
  select: any;
  selectItem: any;
  eventType: any;
  eventText: any;
  giveBalance: any;
  giveID: any;
  giveAgentId: any;
  giveAgentBalance: any;

  begin: any;
  end: any;
  users: any;
  agents: any;

  summary: any;

  selectViewType: any;
  viewType: any;
  code : number;
}

export class BalanceGiveUser extends Component<any, BalanceState> {
  balanceService: BalanceService = new BalanceService();
  agentService: AgentService = new AgentService();
  constructor(props: any) {
    super(props);
    // let today = GetToday();

    let today = GetToday();

    this.state = {
      code : new Date().getTime(),
      summary: [],
      begin: today.begin,
      end: today.end,

      agents: [],
      users: [],
      balances: [],

      agentBalance: 0,
      findText: "",
      searchText: "",
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      select: [
        { label: "선택", value: null },
        { label: "아이디", value: "userId" },
        // { label: "타입", value: "logType" },
      ],

      eventType: [
        { label: "지급", value: "deposit" },
        { label: "회수", value: "withdraw" },
      ],

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "지급", value: "deposit" },
        { label: "회수", value: "withdraw" },
        { label: "에이전트 지급및회수", value: "admin" },
        { label: "롤링/루징", value: "rollingloseing" },
      ],

      selectViewType: "all",

      eventText: "deposit",

      giveBalance: 0,
      giveID: "",

      giveAgentId: "",
      giveAgentBalance: 0,
    };
  }

  componentDidMount() {
    this.handlePageChange(
      1,
      this.state.findText,
      this.state.searchText,
      this.state.selectViewType
    );
    this.agentService.agent_get_anget_and_user().then((data) => {
      console.log(data);
      let users: any = [];
      for (let user of data.users) {
        users.push({ label: user.id, value: user.id });
      }

      let agents: any = [];
      for (let user of data.agents) {
        agents.push({ label: user.agent, value: user.agent });
      }
      console.log(data.summary);
      this.setState({
        agents,
        users,
      });
    });
  }

  handlePageChange(
    page: number,
    findText: string,
    searchText: string,
    view: string
  ) {
    this.setState({ activePage: page });
    this.balanceService
      .agent_balance_log(
        page,
        findText,
        searchText,
        this.state.begin,
        this.state.end,
        view
      )
      .then((data) => {
        this.setState({
          summary: data.summary,

          agentBalance: data.agentBalance,
          balances: data.balances,
          maxCount: data.maxCount,
        });
      });
  }

  handleDoAgentDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 에이전트에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_agent(
                this.state.giveAgentBalance,
                this.state.giveAgentId
              )
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.handlePageChange(
                            1,
                            this.state.findText,
                            this.state.searchText,
                            this.state.selectViewType
                          );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_deposit_to_user(this.state.giveBalance, this.state.giveID, this.state.code)
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.handlePageChange(
                            1,
                            this.state.findText,
                            this.state.searchText,
                            this.state.selectViewType
                          );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }
  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.balanceService
              .agent_withdraw_to_user(this.state.giveBalance, this.state.giveID, this.state.code)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "회수",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.handlePageChange(
                            1,
                            this.state.findText,
                            this.state.searchText,
                            this.state.selectViewType
                          );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "회수",
                    message: "유저 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "회수",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => {},
        },
      ],
    });
    return;
  }

  render() {
    const RenderGiveUserBalance = () => {
      return (
        <div className="p-col-12 p-md-12">
          <div className="p-grid form-group">
            <div className="p-col-12 p-md-3">
              <Dropdown
                options={this.state.eventType}
                value={this.state.eventText}
                onChange={(event: any) =>
                  this.setState({
                    eventText: event.value,
                  })
                }
                style={{ width: "12em" }}
              />
            </div>
            <div className="p-col-12 p-md-3">
              <Dropdown
                value={this.state.giveID}
                options={this.state.users}
                onChange={(event: any) => {
                  this.setState({
                    giveID: event.value,
                  });
                }}
                style={{ width: "12em" }}
                editable={true}
                placeholder="유저 아이디"
              />
            </div>
            <div className="p-col-12 p-md-3" style={{ paddingTop: 20 }}>
              <span className="md-inputfield">
                <InputText
                  id="balance"
                  value={this.state.giveBalance}
                  onChange={(event: any) => {
                    try {
                      if (
                        isNaN(
                          Number(event.target.value.split(",").join(""))
                        ) === false
                      ) {
                        this.setState({
                          giveBalance: Number(event.target.value),
                        });
                      }
                    } catch (ex) {}
                  }}
                />

                <label htmlFor="balance">지급금액</label>
              </span>
            </div>
            <div className="p-col-12 p-md-3">
              <Button
                label="확인"
                onClick={() => {
                  console.log(this.state.eventText);
                  if (this.state.eventText === "deposit") {
                    this.handleDoDeposit();
                  } else {
                    this.handleDoWithdraw();
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    };

    const RenderGiveAgentBalance = () => {
      return (
        <div className="p-col-12 ">
          <div className="p-md-8">
            <div className="p-grid form-group">
              <div className="p-col-12 p-md-3">
                <Dropdown
                  value={this.state.giveAgentId}
                  options={this.state.agents}
                  onChange={(event: any) => {
                    this.setState({
                      giveAgentId: event.value,
                    });
                  }}
                  style={{ width: "12em" }}
                  editable={true}
                  placeholder="에이전트아이디"
                />
              </div>
              <div className="p-col-12 p-md-3">
                <span className="md-inputfield">
                  <InputText
                    value={this.state.giveAgentBalance}
                    onChange={(event: any) => {
                      try {
                        if (
                          isNaN(
                            Number(event.target.value.split(",").join(""))
                          ) === false
                        ) {
                          this.setState({
                            giveAgentBalance: Number(event.target.value),
                          });
                        }
                      } catch (ex) {}
                    }}
                  />
                  <label>지급금액</label>
                </span>
              </div>
              <div className="p-col-12 p-md-3">
                <Button
                  label="확인"
                  onClick={() => {
                    this.handleDoAgentDeposit();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>회원/파트너 캐시내역</h4>
              </div>
              <div className="panel-body">
                <div className="form-inline">
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      showWeek={false}
                      value={new Date(this.state.begin)}
                      onChange={(e: any) =>
                        this.setState({
                          begin: e.value.toLocaleString("sv-SE"),
                        })
                      }
                      showTime
                      locale={ConvertCalendar()}
                    />
                    &nbsp;~&nbsp;
                    <Calendar
                      placeholder="BegEndin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      showWeek={false}
                      value={new Date(this.state.end)}
                      onChange={(e: any) =>
                        this.setState({
                          end: e.value.toLocaleString("sv-SE"),
                        })
                      }
                      showTime
                      locale={ConvertCalendar()}
                    />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <Dropdown
                      options={this.state.select}
                      value={this.state.findText}
                      onChange={(event: any) =>
                        this.setState({
                          findText: event.value,
                        })
                      }
                      autoWidth={false}
                      className="form-control"
                      style={{ width: "120px" }}
                    />
                  </div>
                  <div className="form-group" style={{ paddingRight: "10px" }}>
                    <span className="md-inputfield">
                      <InputText
                        onChange={(event: any) => {
                          this.setState({
                            searchText: event.target.value,
                          });
                        }}
                        className="form-control"
                        style={{ width: "200px" }}
                      />
                      <label>검색</label>
                    </span>
                  </div>
                  {/* Button Start */}
                  <Button
                    label="검색"
                    onClick={() => {
                      this.handlePageChange(
                        1,
                        this.state.findText,
                        this.state.searchText,
                        this.state.selectViewType
                      );
                    }}
                  />
                  {/* Button End */}
                </div>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <SelectButton
                    value={this.state.selectViewType}
                    options={this.state.viewType}
                    onChange={(event) => {
                      // this.state.selectViewType;
                      this.setState({ selectViewType: event.value });

                      this.handlePageChange(
                        0,
                        this.state.findText,
                        this.state.searchText,
                        event.value
                      );
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <DataTable
                    style={{ padding: "0px" }}
                    value={this.state.summary}
                    paginatorPosition="both"
                    rows={20}
                  >
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="error" text="충전" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>
                              {numTomoeny(rowData.deposit) +
                                "(" +
                                numTomoeny(rowData.depositCount) +
                                ")"}
                            </span>
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="info" text="환전" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>
                              {numTomoeny(rowData.withdraw) +
                                "(" +
                                numTomoeny(rowData.withdrawCount) +
                                ")"}
                            </span>
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="info" text="충-환전" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>
                              {numTomoeny(rowData.deposit - rowData.withdraw)}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </DataTable>
                </div>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handlePageChange(
                      page,
                      this.state.findText,
                      this.state.searchText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>
              </div>
              <div className="panel-body">
                <LogAgentBalanceTable
                  logs={this.state.balances}
                ></LogAgentBalanceTable>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handlePageChange(
                      page,
                      this.state.findText,
                      this.state.searchText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

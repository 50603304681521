import axios from "axios";

import { AxiosMag } from "./axios.service";

export class AgentService {

  get_dashboard = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_dashboard", {});
  };
  
  admin_get_management = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_management", {});
  };

  agent_check_health = async () => {
    return await AxiosMag.SendMessageByPost("/agent/agent_check_health", {});
  };

  agent_get_calculate = async (agent_name: String, begin: Date, end: Date) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_calculate", {
      agent_name: agent_name,
      begin: begin,
      end: end,
    });
  };

  agent_get_list = async () => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_list", {});
  };

  agent_get_minigame_list = async () => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_get_minigame_list",
      {}
    );
  };

  agent_get_agnet_balance_log = async (page: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_get_agnet_balance_log",
      { skip: page }
    );
  };

  agent_get_agent_balance_list = async (begin : any, end: any, skip : any , selectView : string, find: string, text : string) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_get_agent_tree_balance_exchange",
      { 
        begin,
        end,
        skip,
        find, 
        text,
        selectView}
    );
  };

  admin_get_agent_benefit = async (agentId: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_benefit", {
      agentid: agentId,
    });
  };

  
  admin_update_agent_benefit = async (benefits: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_update_agent_benefit", {
      benefits,
    });
  };
  
  
  admin_read_agent_help = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_read_agent_help", {
      id : id,
    });
  };
  

  admin_get_agent_help_del = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_help_del", {
      id : id,
    });
  };
  
  admin_get_agnet_detail = async (agentId: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agnet_detail", {
      agentid: agentId,
    });
  };

  agent_get_agent_benefit = async () => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_get_agent_benefit",
      {}
    );
  };

  agent_get_all_agent = async () => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_all_agent", {});
  };

  agent_get_down_agent = async (agent: string, level: number) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_down_agent", {
      agent: agent,
      level: level,
    });
  };

  agent_get_agent_info = async () => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_agent_info", {});
  };

  agent_get_benefits = async () => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_benefits", {});
  };

  agent_exchange_balance = async (data : any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_exchange_balance",
      data
    );
  };
  
  
  agent_exchange_rolling = async (data : any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_exchange_rolling",
      data
    );
  };
  
  
  agent_withdraw_balance = async (data : any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_withdraw_balance",
      data
    );
  };
  
  agent_exchange_money = async (data : any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_exchange_money",
      data
    );
  };

  
  agent_change_balance = async (data : any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_change_balance",
      data
    );
  };

  
  agent_save_agent = async (agent : any) => {

    // if(process.env.REACT_APP_API_USER_REG === 'Y') {
      return await AxiosMag.SendMessageByPost(
        "/agent/agent_edit_save_slot_agent",
        agent
      );
    // }

    // return await AxiosMag.SendMessageByPost(
    //   "/agent/agent_edit_none_reg_save_slot_agent",
    //   agent
    // );
  };

  agent_get_slot_list = async (
    skip: number,
    findText: any,
    searchText: any,
    begin: any,
    end: any
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_slot_none_rolling_list", {
      skip,
      findText,
      searchText,
      begin,
      end,
    });
  };
  
  agent_get_slot_none_rolling_sum = async (
    skip: number,
    findText: any,
    searchText: any,
    begin: any,
    end: any
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_slot_none_rolling_sum", {
      skip,
      findText,
      searchText,
      begin,
      end,
    });
  };

  

  agent_get_casino_list = async (skip: number, begin: any, end: any, findText :any, searchText : any) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_casion_list", {
      skip,
      begin,
      end,
      findText,
      searchText
    });
  };

  agent_get_fx_list = async (skip: number) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_fx_list", {
      skip,
    });
  };

  agent_get_anget_and_user = async () => {
    return await AxiosMag.SendMessageByPost(
      "/agent/agent_get_agent_and_user",
      {}
    );
  };

  
  admin_get_notice_agent = async () => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_notice_agent",
      {}
    );
  };

  admin_get_agent_help_list = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agent_help_list",
      {skip}
    );
  };

  admin_wirte_agent_help = async (params) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_wirte_agent_help",
      params
    );
  };

  
  admin_get_agent_note_list = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agent_note_list",
      {skip}
    );
  };

    

  admin_read_agent_note = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_read_agent_note", {
      id : id,
    });
  };

  admin_del_agent_note = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_del_agent_note", {
      id : id,
    });
  };


  admin_change_to_password = async (old_pass, new_pass) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_change_to_password", {
        old_pass,
        new_pass
      }
    );
  };
  
};


import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import {
  numTomoeny,
  convertDate,
  getDepositColor,
  getWithdrawColor,
} from "../../utility/help";

interface BalanceProps {
  mode: any;
  balances: any;
  level: any;
  OnEditItem: (par1: any, par2: any, par3: any) => any;
  OnSelectItem: (par: any) => any;
}

export class BalanceTable extends Component<BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
    this.state = {
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
    this.header = this.header.bind(this);
  }

  componentDidMount = () => {};

  header = (up: any, down: any) => {
    return (
      <div>
        <span>{up}</span>
        <br />
        <span>{down}</span>
        <br />
      </div>
    );
  };

  render = () => {
    const mode = this.props.mode;
    const balances = this.props.balances;
    const OnEditItem = this.props.OnEditItem;
    const OnSelectItem = this.props.OnSelectItem;
    const CheckRender = () => {
      if (mode == "deporsitAndwithdraw") {
        return <div style={{ width: "0px" }}></div>;
      }

      return (
        <Column
          style={{ textAlign: "center", width: "3px" }}
          headerStyle={{
            textAlign: "center",
            width: "3px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header=""
          body={(rowData: any, column: any) => {
            return (
              <div>
                <Checkbox
                  value="n"
                  inputId={"idx" + rowData.idx}
                  onChange={() => {
                    OnSelectItem(rowData.idx);
                  }}
                  checked={rowData.sel == "y" ? true : false}
                />
                <label htmlFor="cb1" className="p-checkbox-label"></label>
              </div>
            );
          }}
        />
      );
    };
    return (
      <DataTable
        value={balances}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >
        {CheckRender()}

        <Column
          style={{ textAlign: "center", width: "100px" }}
          headerStyle={{
            textAlign: "center",
            width: "100px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header="종류"
          body={(rowData: any, column: any) => {
            if (rowData.type == "deposit" || rowData.type == "user_deposit") {
              return <div style={{ color: "red" }}>유저 충전 </div>;
            }

            if (rowData.type == "agent_deposit") {
              return <div style={{ color: "red" }}>에이전트 충전</div>;
            }

            if (rowData.type == "admindeposit" || rowData.type === "admin_deposit") {
              return <div style={{ color: "red" }}>관리자 충전 </div>;
            }

            if (rowData.type == "withdraw"|| rowData.type == "user_withdraw") {
              return <div style={{ color: "blue" }}>유저 환전</div>;
            }

            if (rowData.type == "agent_withdraw") {
              return <div style={{ color: "blue" }}>에이전트 환전</div>;
            }

            if (rowData.type == "adminwithdraw"  || rowData.type === "admin_withdraw") {
              return <div style={{ color: "blue" }}>관리자 환전</div>;
            }

            return "미정의";
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          headerStyle={{
            textAlign: "center",
            width: "120px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header="아이디"
          field="id"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: rowData.color }} onClick={() => {}}>
                {rowData.userId}
                <i
                  className="pi pi-star-o"
                  style={{ fontSize: "12px", color: "#ff5722" }}
                ></i>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          headerStyle={{
            textAlign: "center",
            width: "120px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header="에이전트"
          field="id"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: rowData.color }} onClick={() => {}}>
                {rowData.agentId}
                <i
                  className="pi pi-star-o"
                  style={{ fontSize: "12px", color: "#ff5722" }}
                ></i>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          headerStyle={{
            textAlign: "center",
            width: "100px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header="입금/출금"
          body={(rowData: any, column: any) => {
            return (
              <div
                style={{
                  color:
                    rowData.balance > 0
                      ? getDepositColor()
                      : getWithdrawColor(),
                }}
              >
                {numTomoeny(rowData.balance)}
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "140px" }}
          headerStyle={{
            textAlign: "center",
            width: "140px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header={"완료일"}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <span>{convertDate(rowData.regDate)}</span>
              </div>
            );
          }}
        />
        {/* <Column
          style={{ textAlign: "center", width: "300px" }}
          headerStyle={{
            textAlign: "center",
            width: "300px",
            background: "#27765c",
            color: "#fff",
            borderRight: "1px solid #d8d8d8",
          }}
          header={"소속"}
          bodyStyle={{ textAlign: "left", width: "300px" }}
          body={(rowData: any, column: any) => {
            return <div>{rowData.agent}</div>;
          }}
        /> */}
      </DataTable>
    );
  };
}

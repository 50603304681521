import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";

interface WrapperProe {
  location: any;
}

class AppWrapper extends Component<any, WrapperProe> {
  // constructor(props: WrapperProe) {
  //   super(props);
  // }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    switch (this.props.location.pathname) {
      case "/":
        return <Route path="/" component={Login} />;
      case "/error":
        return <Route path="/error" component={Error} />;
      case "/notfound":
        return <Route path="/notfound" component={NotFound} />;
      case "/access":
        return <Route path="/access" component={Access} />;
      default:
        return <App />;
    }
  }
}

export default withRouter(AppWrapper);

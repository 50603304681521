import React, { Component } from "react";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetMiniTable } from "../share/bet/bet-mini-table";
import { CustomPagination } from "../share/custom-pagination";
import { UserService } from "../service/user.service";
import { numTomoeny, GetToday, ConvertCalendar } from "../utility/help";

interface MiniState {
  begin: any;
  end: any;
  userTableValue: any;
  findText: any;
  searchText: any;
  page: any;
  cities: any;
  maxCount: any;
  summary: any;
}

export class BetMini extends Component<any, MiniState> {
  userService: any = new UserService();
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      summary: {},
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
    };

    // this.handlePageChange = this.handlePageChange.bind(this);

    this.get_mini_bet_list(0);
  }

  componentDidMount() {}

  get_mini_bet_list = (page: any) => {
    this.userService
      .agent_get_mini_bet_list(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText
      )
      .then((data: any) => {
        console.log(data.minis);
        this.setState({
          userTableValue: data.minis,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_mini_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;

    return (
      <div>
        <div className="row gutter">
          <div className="col-lg-12">
            <div className="panel">
              <div className="panel-heading">
                <h4>미니게임 배팅정보</h4>
              </div>
              <div className="panel-body">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline">
                    <div
                      className="form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <Calendar
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.begin}
                        showWeek={false}
                        onChange={(e) => this.setState({ begin: e.value })}
                        locale={ConvertCalendar()}
                      />
                      &nbsp;~&nbsp;
                      <Calendar
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.end}
                        showWeek={false}
                        onChange={(e) => this.setState({ end: e.value })}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <Dropdown
                        options={this.state.cities}
                        value={this.state.findText}
                        onChange={(event) =>
                          this.setState({ findText: event.value })
                        }
                        autoWidth={true}
                        className="form-control"
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ paddingRight: "10px" }}
                    >
                      <span className="md-inputfield">
                        <InputText
                          onChange={(event: any) => {
                            this.setState({
                              searchText: event.target.value,
                            });
                          }}
                          className="form-control"
                        />
                      </span>
                    </div>
                    {/* Button Start */}
                    <Button
                      label="검색"
                      onClick={() => {
                        this.handlePageChange(0);
                      }}
                    />
                    {/* Button End */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <DataTable
                    style={{ padding: "0px" }}
                    value={this.state.summary}
                    paginatorPosition="both"
                    rows={20}
                  >
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="error" text="BET" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>{numTomoeny(rowData.totalBet)}</span>
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="info" text="WIN" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>{numTomoeny(rowData.totalWin)}</span>
                          </div>
                        );
                      }}
                    />
                  </DataTable>
                </div>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
              <div className="panel-body">
                <BetMiniTable bets={this.state.userTableValue}></BetMiniTable>
              </div>
              <div className="panel-body">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
